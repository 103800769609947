import  { FC } from "react";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";

type Props = {
    color: string;
    changeColor: (color: string) => void;
};

const ExcelColorItem: FC<Props> = ({ color, changeColor }) => {
    const [currentColor, setCurrentColor] = useColor(color);

    function updateColor(color: IColor) {
        setCurrentColor(color);
        changeColor(color.hex);
    }

    return <ColorPicker color={currentColor} onChange={updateColor} />;
};

export default ExcelColorItem;
