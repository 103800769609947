import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IEventObject,
  IEventPosition,
  IPointEvent,
} from "../../services/pointEvents/pointEventsSlice";
import {
  useAcceptDraftSupplyPointEvent,
  useDeleteDraftSupplyPointEvent,
  useGetDraftSupplyPointEventObjects,
  useGetDraftSupplyPointEventPositions,
  useGetDraftSupplyPointEvents,
} from "../../hooks/pointEventsHook";
import "./styles.scss";
import "moment-timezone";
import { Positions, Series } from "./types";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  drawReserveValue,
  fillChartWithValues,
  fillChartWithZeros,
} from "./helpers";
import dayjs from "dayjs";
import { useDebounce } from "../../app/hooks";
import "moment/locale/ru";
import { AddButton, BigLoader, Filters } from "../../components/UI";
import { IFilter, IFilterFabric } from "shared/types/filters";
import { useFetch, useFilters } from "hooks";
import { SearchInput } from "shared/components";
import EventModalContainer from "../../components/EventModal/EventModalContainer";
import { getAllTypes } from "entities/Types/api";
import EventTable from "../../components/Widgets/PointEventsPage/EventTable";
import { useFirstLoading } from "hooks/useFirstLoading";



export const PointEventsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const location = useLocation();
  const role = useMemo(() => {
    return location.pathname.includes('lineman') ? 'lineman' : 'acceptor'
  }, [location.pathname])

  const [eventModalVisible, setEventModalVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<IPointEvent>(); //true-edit, false-create
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);
  const [beginDate, setBeginDate] = useState<dayjs.Dayjs>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();
  const [currentObject, setCurrentObject] = useState<IEventObject>();
  const [currentPosition, setCurrentPosition] = useState<IEventPosition>();

  
  const { availableEventObjects, availableEventObjectsUniqNames } =
    useGetDraftSupplyPointEventObjects({});

  const {data: availableTypes, isLoading: typesLoading} = useFetch(getAllTypes) 
  
  

  const { availableEventPositions, getPositionsByPointId } =
    useGetDraftSupplyPointEventPositions({});
  const { deletePointEvent } = useDeleteDraftSupplyPointEvent();
  const { acceptPointEvent } = useAcceptDraftSupplyPointEvent();

  const onCreateEvent = () => {
    setEventModalVisible(true);
    setCurrentEvent(undefined);
  };

  useEffect(() => {
    currentObject?.SupplyPointMappingId &&
      getPositionsByPointId({ supplyPointId: currentObject?.SupplyPointId });
  }, [currentObject]);

 

  useEffect(() => {
    if (
      availableEventObjects?.length &&
      availableEventObjectsUniqNames?.length &&
      searchParams?.get("curDate") &&
      searchParams?.get("spId")
    ) {
      //@ts-ignore
      const year = +searchParams?.get("curDate")?.split("-")?.[0];
      //@ts-ignore
      const month = +searchParams?.get("curDate")?.split("-")?.[1];
      //@ts-ignore
      const date = +searchParams?.get("curDate")?.split("-")?.[2];
      searchParams?.get("curDate") &&
        year &&
        month &&
        date &&
        setBeginDate(
          dayjs()
            .set("year", year)
            .set("month", month)
            .set("date", date)
            .subtract(1, "month")
        );
      //@ts-ignore
      getPositionsByPointId({ supplyPointId: +searchParams?.get("spId") });
      setCurrentObject(
        availableEventObjectsUniqNames?.find(
          (uniq) =>
            availableEventObjects?.find(
              //@ts-ignore
              (obj) => obj.Id === +searchParams?.get("spId")
            )?.SupplyPointName === uniq?.SupplyPointName
        )
      );
      
      setShowFiltersPanel(true);
      navigate(`/point-events-acceptor`);
      localStorage.setItem("role", "acceptor");
    }
  }, [availableEventObjects, availableEventObjectsUniqNames, searchParams]);

  const onEditEvent = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    curr: IPointEvent
  ) => {
    e.stopPropagation();
    setEventModalVisible(true);
    setCurrentEvent(curr);
  };

  const onDeleteEvent = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    const answer = window.confirm("Удалить?");
    if (answer) {
      deletePointEvent({
        id,
        onSuccess(data) {
          refresh();
        },
      });
    }
  };

  function callAcceptEvent(cur: IPointEvent) {
    setCurrentEvent(cur);
    setEventModalVisible(true);
  }

  // улучшить функцию одобрения собтыия
  const onAcceptEvent = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    acceptPointEvent({
      id,
      onSuccess(data) {
        refresh();
        closeModal()
      },
      onError() {
        alert("Ошибка");
      },
    })
  }, [acceptPointEvent]);


  const filterConfig = useMemo<{
      config: IFilter[];
      fabric: IFilterFabric[];
  }>(() => {
      const objects = availableEventObjectsUniqNames.map((el) => ({
          key: el.SupplyPointName,
          value: el.SupplyPointId.toString(),
      }));
      const search: IFilterFabric = {name: 'searchPattern', type: 'Input'}
      const result: IFilter[] = [
          { name: "beginDate", type: "Date", placeholder: "Дата начала" },
          { name: "endDate", type: "Date", placeholder: "Дата окончания" },
          {
              name: "supplyPointId",
              type: "Dropdown",
              options: objects,
              placeholder: "Объект:",
          },
      ];
      return {
          fabric: [...result, search],
          config: result,
      };
  }, [
      availableEventObjectsUniqNames,
      availableEventPositions,
      currentObject?.SupplyPointId,
  ]);

  const {filter, updateFilters} = useFilters(filterConfig.fabric);

  const debouncedSearchPattern = useDebounce(filter.searchPattern);
  const { allPointEvents, refresh } = useGetDraftSupplyPointEvents({
    searchPattern: debouncedSearchPattern ?? '',
    beginDate: filter.beginDate ?? undefined,
    endDate: filter.endDate ?? undefined,
    supplyPointId: filter.supplyPointId ?? undefined,
    
  });
  const chartsData = useMemo(() => {
    const chartsDataLocal: Record<string, Series[]> = {};
    allPointEvents?.forEach((point) => {
      let data: Series[] = [];
      const dataModifier: Positions[] = [];
      const actualType = availableTypes?.find(type => type.Id === point.TypeId)?.DraftSupplyPointEventOperationType
      if (actualType !== 'DRILL') {
        data = drawReserveValue(point)
      }
       else  {
        point?.ModifierData?.forEach((position, i) => {
          fillChartWithValues(dataModifier, position);
        });
        fillChartWithZeros(dataModifier);
        data?.push({
          label: point.SupplyPointName as string,
          data: dataModifier,
        },
        
      );
      } 
      chartsDataLocal[String(point?.Id)] = [...data];
    });
    return chartsDataLocal;
  }, [allPointEvents]);

  useEffect(() => {
    if (!filter.supplyPointId) return setCurrentObject(undefined);
    setCurrentObject(
      availableEventObjectsUniqNames?.find(
        (uniq) =>
          availableEventObjects?.find(
            //@ts-ignore
            (obj) => obj.Id === +filter.supplyPointId
          )?.SupplyPointName === uniq?.SupplyPointName
      )
    )
  }, [filter.supplyPointId])

  const closeModal = () => {
    setEventModalVisible(false);
  }

  const isLoading = useFirstLoading(typesLoading || !allPointEvents)

  return (
      <>
          <h1 className="event-page-header">События</h1>
          {isLoading ? (
              <BigLoader />
          ) : (
              <>
                  <div className="flex-between">
                      <SearchInput
                          className="search"
                          placeholder="Умный поиск"
                          onChange={(e) =>
                              updateFilters("searchPattern", e.target.value)
                          }
                      />
                      {role === "lineman" && (
                          <AddButton onClick={onCreateEvent}>
                              Создать событие
                          </AddButton>
                      )}
                  </div>

                  <Filters
                      title="Фильтр"
                      filters={filterConfig.config}
                      value={filter}
                      onChangeFilters={updateFilters}
                  />

                  <EventTable
                      allPointEvents={allPointEvents!}
                      chartsData={chartsData}
                      onDeleteEvent={onDeleteEvent}
                      callAcceptEvent={callAcceptEvent}
                      role={role}
                      onEditEvent={onEditEvent}
                  />
                  {eventModalVisible && (
                      <EventModalContainer
                          currentEvent={currentEvent}
                          onClose={closeModal}
                          availableTypes={availableTypes ?? []}
                          searchPatternFilter={debouncedSearchPattern ?? ""}
                          onAcceptEvent={onAcceptEvent}
                          beginDateFilter={beginDate?.format(
                              "YYYY-MM-DDT00:00"
                          )}
                          endDateFilter={endDate?.format("YYYY-MM-DDT00:00")}
                          supplyPointIdFilter={currentObject?.SupplyPointId}
                          eventSupplyPointMappingIdFilter={
                              currentPosition?.SupplyPointMappingId
                          }
                          role={role}
                      />
                  )}
              </>
          )}
      </>
  );
};

export default PointEventsPage;
