import { useAppSelector } from "app/hooks";
import { IGuideRate, TableTabItem } from "../types";
import { resourceDictionary } from "entities/ISGuide/types";

const isIGuideRate = (item: any): item is IGuideRate => {
    return "EnergySourceId" in item;
};

type ReturnRype = (val: unknown) => TableTabItem;

const dict: Record<string, string> = {
    "Сжатый воздух": "Сж. воздух",
};

export const useArchiveToReport = (): ReturnRype => {
    const { resources } = useAppSelector((state) => state.Excel);

    const generateReportName = (val: unknown): TableTabItem => {
        if (isIGuideRate(val)) {
            const current = resources.find(
                (el) => el.EnergySourceId === val.EnergySourceId
            );
            return {
                name: `${
                    current?.AxisName
                        ? dict[current.AxisName] ?? current.AxisName
                        : ""
                } ${val.StartDate.split("-")[0]} ${
                    resourceDictionary[val.Mode].short
                }`,
                fullName: `${current?.AxisName ?? ""} ${
                    val.StartDate.split("-")[0]
                } / ${resourceDictionary[val.Mode].many} нормы`,
                filtersState: {},
                id: val.Id,
                tableId: val.ReportTableId,
            };
        }
        return {
            name: "",
            fullName: "",
            filtersState: {},
            id: 0,
            tableId: 0,
        };
    };

    // const currentReportName = useMemo<TableTabItem>(() => {
    //     return generateReportName(item);
    // }, [item]);

    return generateReportName;
};
