import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ITableLink } from "entities/ExcelTable/types";

type Props = {
    links: ITableLink[];
};

const LinksMainList: FC<Props> = ({ links }) => {
    const isAlone = links.length === 1;

    return (
        <div className={styles.linksList}>
            {links.map((link) => (
                <TableLink link={link} isAlone={isAlone} key={link.name} />
            ))}
        </div>
    );
};

export default LinksMainList;

type LinkProps = {
    link: ITableLink;
    isAlone: boolean;
};

const TableLink: FC<LinkProps> = ({ link, isAlone }) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        setIsActive(isAlone);
    }, [isAlone]);

    const navigate = useNavigate();
    const timer = useRef<ReturnType<typeof setInterval> | null>(null);

    const onOver = () => {
        if (!isAlone) {
            timer.current = setTimeout(() => {
                setIsActive(true);
            }, 300);
        }
    };

    const onLeave = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        !isAlone && setIsActive(false);
    };

    const hasChildren = !!link.children?.length;
    return (
        <div className={styles.linkList} onMouseLeave={onLeave}>
            <button
                className={classNames(styles.link, {
                    [styles.active]: hasChildren && isActive,
                    [styles.last]: !(hasChildren && isActive),
                })}
                onMouseOver={onOver}
                onClick={() => {
                    if (hasChildren) {
                        setIsActive((prev) => !prev);
                    } else {
                        navigate(`/is-energy/${link.link}`);
                    }
                }}
            >
                {link.name}
            </button>

            <div
                className={classNames(styles.linksList, {
                    [styles.none]: !isActive,
                })}
            >
                {(link.children ?? []).map((link) => (
                    <TableLink isAlone={false} link={link} key={link.name} />
                ))}
            </div>
        </div>
    );
};
