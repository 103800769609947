import { DefaultButtonConfig } from "../Types/actionCell";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";

export const defaultConfig: DefaultButtonConfig = {
  delete: {
    buttonType: "primary",
    icon: <DeleteOutlined />,
    danger: true,
  },
  save: {
    buttonType: "primary",
    icon: <SaveOutlined />,
    danger: false,
  },
  cancel: {
    buttonType: "default",
    icon: <StopOutlined />,
    danger: true,
  },
  edit: {
    buttonType: "primary",
    icon: <EditOutlined />,
    danger: false,
  },
};
