import { availableYears, months } from "shared/constants/common";
import { IConfig } from "./types";

export const years = availableYears.map((el) => ({
    name: el.key,
    value: el.value.split("-")[0],
}));

export const periods = [
    { value: "allMonth", name: "Весь месяц" },
    { value: "untilToday", name: "До сегодня" },
    { value: "untilDate", name: "До конкретной даты" },
    // { value: "twoWeeks", name: "За две недели" },
    // { value: "threeWeeks", name: "За три недели" },
];
export const config: IConfig[] = [
    {
        title: "Месяц",
        options: months.map((el) => ({ value: el.value, name: el.name.full })),
        name: "month",
    },
    { title: "Год", options: years, name: "year" },
];

export const quartlas = [
    { name: "За 1 квартал", value: "first" },
    { name: "За 2 квартал", value: "second" },
    { name: "За 3 квартал", value: "third" },
    { name: "За 4 квартал", value: "fourth" },
];
