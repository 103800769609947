import { ColorPallete } from "entities/ExcelTable/types";
import { useLocalStorage } from "./useLocalStorage";

type ReturnType = [
    ColorPallete,
    (key: keyof ColorPallete, color: string) => void,
    (value: ColorPallete) => void
];

const Trufanov = true;

export const useColorSettings = (): ReturnType => {
    const [colors, setColors] = useLocalStorage<ColorPallete>(
        "colors",
        {
            header: "#fefe22",
            name: "#ccffcc",
            column: "#ccffcc",
        },
        Trufanov
    );

    function changeColor(key: keyof ColorPallete, color: string) {
        setColors((prevColors) => ({
            ...prevColors,
            [key]: color,
        }));
    }
    function rewriteColors(value: ColorPallete) {
        setColors(value);
    }

    return [colors, changeColor, rewriteColors];
};
