import { Input, InputProps } from "antd";
import { FC } from "react";
import styles from "./styles.module.css";
import { SearchOutlined } from "@ant-design/icons";
import classNames from "classnames";

const SearchInput: FC<InputProps & { compacted?: boolean }> = (props) => {
    const { className, compacted, ...rest } = props;

    return (
        <div
            className={classNames(styles["search-input"], {
                [styles.compacted]: compacted,
            })}
        >
            <SearchOutlined className={styles["search-icon"]} />{" "}
            <Input
                className={classNames(styles["search-item"], className)}
                {...rest}
            />{" "}
        </div>
    );
};

export default SearchInput;
