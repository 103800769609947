import React, { FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type Props = {
    isActive: boolean;
    zIndex?: number;
    className?: string;
};

const DisabledSpace: FC<PropsWithChildren<Props>> = (props) => {
    const { children, isActive, zIndex, className } = props;

    const zIndexValue = zIndex ?? "auto";

    return (
        <div className={classNames(styles["container-space"], className)}>
            <div
                className={classNames(styles["container-space-blocked"], {
                    [styles.active]: isActive,
                })}
                style={{ zIndex: zIndexValue }}
            />
            {children}
        </div>
    );
};

export default DisabledSpace;
