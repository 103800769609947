import { Flex, Radio, RadioChangeEvent } from "antd";
import { FC, useCallback, useState } from "react";
import { IPointEvent } from "services/pointEvents/pointEventsSlice";
import RadioVariant from "./components/RadioVariant";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import RedButton from "shared/components/RedButton";

type Props = {
    currentEvent: IPointEvent;
};

const variants = [
    {
        title: "Одобрить",
        description: "Выдать статус одобренного события",
        value: true,
    },
    // {
    //     title: "Отклонить",
    //     description: "Выдать статус заблокированного события",
    //     value: false,
    // },
];

const PeriodAccepting: FC<Props> = (props) => {
    const { currentEvent } = props;
    const [value, setValue] = useState<boolean | null>(null);

    const { onAcceptEvent } = usePointEventsContext();

    const changeValue = useCallback(
        (e: RadioChangeEvent) => {
            setValue(e.target.value);
        },
        [setValue]
    );

    return (
        <Radio.Group value={value} onChange={changeValue} buttonStyle="outline">
            <Flex vertical gap={32}>
                {" "}
                <Flex vertical gap={12}>
                    {variants.map((el) => (
                        <RadioVariant key={el.title} {...el} />
                    ))}
                </Flex>
                <RedButton
                    onClick={(e) => onAcceptEvent(e, currentEvent.Id ?? 0)}
                    disabled={value === null}
                >
                    Сохранить
                </RedButton>
            </Flex>
        </Radio.Group>
    );
};

export default PeriodAccepting;
