import React, { FC, ReactNode, createContext, useContext } from "react";
import { DataSource } from "../Types";

interface IEditableCellContext {
  activeItem?: DataSource;
  record: DataSource;
  changeEditing: () => void;
  refetch?: () => Promise<void>;
}

export const EditableCellContext = createContext<
  IEditableCellContext | undefined
>(undefined);

export const useEditableCellContext = () => {
  const context = useContext(EditableCellContext);
  if (!context) throw new Error("context error");
  return context;
};
