import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import { ConfigProvider } from "antd";
import classNames from "classnames";

type Props = {
    name: string;
    path: string;
};

const RouteTab: FC<Props> = (props) => {
    const { name, path } = props;

    const location = useLocation();

    return (
        <ConfigProvider
            theme={{
                inherit: false,
                token: {
                    colorLink: "black",
                },
            }}
        >
            <div
                className={classNames(styles["route-tab"], {
                    [styles.active]: location.pathname === path,
                })}
            >
                <Link className={styles['route__link']} to={path}>{name}</Link>
            </div>
        </ConfigProvider>
    );
};

export default RouteTab;
