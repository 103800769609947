import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RestInstanse } from "../../app/axiosInstance";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISpItem } from "entities/Positions/types";
import { getAuth } from "helpers/getAuth";

export interface IObjectState {
    objects: ISpItem[];
    isLoading: boolean;
    error: string | null;
}

const initialState: IObjectState = {
    objects: [],
    isLoading: false,
    error: null,
};

export const getObjects = createAsyncThunk(
    "asyncgrtObjectsThunk",
    async (_, thunkApi) => {
        const response = await RestInstanse.get<{ SupplyPoints: ISpItem[] }>(
            "/spoints/all",
            {
                ...getAuth(),
            }
        );
        return response.data.SupplyPoints;
    }
);

export const objectSlice = createSlice({
    name: "objectSlice",
    initialState,
    reducers: {},
    extraReducers: {
        [getObjects.fulfilled.type]: (
            state,
            action: PayloadAction<ISpItem[]>
        ) => {
            state.isLoading = false;
            state.error = null;
            state.objects = action.payload;
        },
        [getObjects.pending.type]: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        [getObjects.rejected.type]: (state) => {
            state.isLoading = false;
            state.error = "Ошибка при загрузке объектов";
            state.objects = [];
        },
    },
});

export const useGetObjects = () => {
    const dispatch = useAppDispatch();

    const { objects, isLoading } = useAppSelector((state) => state.Objects);

    const getObjectsHandler = () => {
        if (!(objects.length || isLoading)) dispatch(getObjects());
    };

    return getObjectsHandler;
};
