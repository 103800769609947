import Skeleton from "../components/Skeleton";
import { DirectionType } from "../types/skeleton";

interface Props {
    isLoading: boolean;
}

export const withSkeleton = <P extends object>(
    Component: React.ComponentType<P>,
    count?: number,
    direction?: DirectionType
) => {
    return (props: Props & P) => {
        const { isLoading, ...restProps } = props;

        if (isLoading) {
            return <Skeleton count={count} direction={direction} />;
        }

        return <Component {...(restProps as P)} />;
    };
};
