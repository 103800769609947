import { Modal, Flex } from "antd";
import  { FC } from "react";
import styles from "./style.module.css";
import RedButton from "shared/components/RedButton";

type Props = {
    closeModal: () => void;
    id: number | null;
    onAcceptModal: () => void;
};

const GuideExistModal: FC<Props> = (props) => {
    const { closeModal, id, onAcceptModal } = props;

    return (
        <Modal
            open={!!id}
            onClose={closeModal}
            width={900}
            centered
            height={400}
            footer={false}
            onCancel={closeModal}
        >
            <div className={styles["guide-exist__modal"]}>
                <strong className={styles['guide-exist__description']}>
                    Нормативы на этот год по выбраным категориям уже существуют
                </strong>
                <Flex gap={8}>
                    <RedButton className={styles["guide-existing__btn"]} variant="gray" onClick={closeModal}>
                        Отмена
                    </RedButton>
                    <RedButton
                        className={styles["guide-existing__btn"]}
                        onClick={onAcceptModal}
                    >
                        Открыть существующий
                    </RedButton>
                </Flex>
            </div>
        </Modal>
    );
};

export default GuideExistModal;
