import { Flex, Modal } from "antd";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TabsItem } from "entities/ExcelTable/types";
import Tab from "./Tab";
import { useActions, useAppSelector } from "app/hooks";
import { Slider } from "shared/components";

type Props = {
    items: TabsItem[];
    deleteItem: (id: number) => void;
    onCreateItem: () => void;
    createTabTitle: string;
    isEditing: boolean;
};

const TabsList: FC<Props> = (props) => {
    const { items, deleteItem, onCreateItem, createTabTitle, isEditing } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { changeActiveIndex, clearEditings } = useActions();
    const { activeIndex, promises } = useAppSelector((state) => state.Excel);

    const onCloseOpen = () => {
        setCurId(null);
        setIsOpen(false);
    };
    const cleanCurrentindex = () => {
        if (activeIndex === curId) {
            const current = items.find((el) => el.id !== curId);

            changeActiveIndex(
                current ? { rate: current.id, table: current.tableId } : null
            );
        }
        onCloseOpen();
    };

    useEffect(() => {
        if (!items.find((el) => el.id === curId)) {
            setCurId(null);
        }
    }, [items]);

    const [curId, setCurId] = useState<number | null>(null);

    const currentItem = useMemo<TabsItem | null>(() => {
        const item = items.find((el) => el.id === curId);
        if (!item) return items?.[0] ?? null;
        return item;
    }, [curId, items]);

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            // @ts-ignore
            ref.current.calculateWidth();
        }
    }, [items]);

    const isInPromises = useMemo<boolean>(
        () => Object.keys(promises).includes(String(curId)),
        [promises, curId]
    );

    return (
        <>
            <Modal
                open={isOpen}
                title="Подтвердите удаление"
                centered
                width={900}
                onOk={() => {
                    deleteItem(curId ?? 0);
                    cleanCurrentindex();
                    clearEditings(curId ?? undefined);
                }}
                onCancel={onCloseOpen}
                footer={(_, { OkBtn, CancelBtn }) =>
                    isInPromises ? (
                        <></>
                    ) : (
                        <>
                            <OkBtn />
                            <CancelBtn />
                        </>
                    )
                }
            >
                {isInPromises
                    ? `Отчет "${currentItem?.name}" имеет измененные ячейки. Примите изменения или отмените их перед удалением`
                    : `Вы действительно хотите удалить отчет "${currentItem?.name}"?
                 Перед удалением рекомендуем сохранить все изменения`}
            </Modal>
            <Flex gap={8} align="center">
                <Slider ref={ref} step={260} marginRight={500}>
                    <Flex gap={8} align="center" className="tab-list">
                        {items.map((item) => (
                            <Tab
                                item={item}
                                isEditingMode={isEditing}
                                key={item.id}
                                openModal={(val: number) => {
                                    setCurId(val);
                                    setIsOpen(true);
                                }}
                            />
                        ))}
                    </Flex>
                </Slider>

                <Tab
                    item={{
                        name: createTabTitle,
                        id: Infinity,
                        tableId: Infinity,
                    }}
                    openModal={() => {}}
                    onCreateItem={onCreateItem}
                />
            </Flex>
        </>
    );
};

export default TabsList;
