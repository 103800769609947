import { RefObject, useEffect, useRef, useState } from "react";

type ReturnType = {
    actualEditable: string | null;
    updateEditable: (id?: string) => void;
    increaseEditableCell: () => void;
};

export const useEditableCell = (
    table: RefObject<HTMLTableElement>
): ReturnType => {
    function updateEditable(id?: string) {
        actualEditableSync.current = id || null;
        setActualEditable(id || null);
    }

    function increaseEditableCell() {
        if (!actualEditable) return;
        const element = document.querySelector(`[data-id="${actualEditable}"]`);
        if (!element) return;

        const [rowInfo, columnInfo] = actualEditable.split(":");
        const row = element.closest("tr") as HTMLTableRowElement;
        let array = Array.from(table.current?.children || []);
        if (!array.length) return;
        array = Array.from(array.at(-1)?.children || []);
        const index = array.indexOf(row) + 1;
        if (index <= array.length - 1) {
            const elem = array[index] as HTMLTableRowElement;
            const isParent = elem.dataset.status === "parent";
            updateEditable(
                isParent
                    ? undefined
                    : [
                          (array[index] as HTMLTableRowElement).dataset.row ||
                              "",
                          columnInfo,
                      ].join(":")
            );
        }
    }

    const [actualEditable, setActualEditable] = useState<string | null>(null);
    const actualEditableSync = useRef<string | null>(null);

    function onClickTable(e: MouseEvent) {
        if (!actualEditableSync.current) return;
        const cell = (e.target as HTMLElement).closest(
            "[data-id]"
        ) as HTMLElement;
        if (!cell || cell.dataset.id !== actualEditableSync.current) {
            updateEditable();
        }
    }

    useEffect(() => {
        document.addEventListener("click", onClickTable);
        return () => {
            document.removeEventListener("click", onClickTable);
        };
    }, []);

    return {
        actualEditable,
        updateEditable,
        increaseEditableCell,
    };
};
