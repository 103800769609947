import { IRadioFilterItem } from "shared/types/is-energy";

export type TemplateValue = "constant" | "variable" | "none";

export type ResourceMode = "CONST" | "NONE" | "VARIABLE";
export const resourceDictionary: Record<
    ResourceMode,
    { short: string; full: string; many: string }
> = {
    CONST: { short: "Пост.", full: "Постоянный", many: "Постоянные" },
    NONE: { short: "Нет", full: "Отсутствует", many: "Отсутствующие" },
    VARIABLE: { short: "Пер.", full: "Переменный", many: "Переменные" },
};

export interface IResource {
    AxisName: string;
    EnergySourceName: string;
    Mode: ResourceMode;
    EffectiveDate?: string;
    PositionId: number;
    EnergySourceId: number;
}

export interface ITemplate {
    PositionId: number;
    ParentId?: number;
    EffectiveDate?: string;
    SupplyPointId: number;
    Position: string;
    SupplyPointName: string;
    Map: IResource[];
    Children?: ITemplate[];
}

export type TemplateMap = Record<string, Record<string, IResource["Mode"]>>;

export type Template = Omit<ITemplate, "Map"> & {
    Map: TemplateMap;
};

export type CreatingForm = {
    title: string;
    value: string;
    items: IRadioFilterItem[];
};

export type ICreatingFormResponse = {
    AxisCode: string;
    AxisName: string;
    EnergySourceName: string;
    EnergySourceId: number;
    AvailableModes: ResourceMode[];
};

export const isResourceMode = (mode: string): mode is ResourceMode => {
    return ["CONST", "NONE", "VARIABLE"].includes(mode);
};

export interface ITemplatePosition {
    Facility: string;
    Id: number;
    Position: string;
    SupplyPointName: string;
    SpId: number;
}

export interface IRatesParams {
    EnergySourceId: string;
    Mode: string;
    Period: string;
}

export type ChildrenCell = {
    key: ITemplate["PositionId"];
    status: boolean;
};

export interface ITemplateResponse {
    body: TemplateMap;
    EffectiveDate: string;
}

export type TemplateResponse = {
    [key: number]: ITemplateResponse;
};
