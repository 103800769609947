import { OperationType } from "../types";

type OpertaionTypeDictionry = Record<OperationType, string>;

export const dictionary: OpertaionTypeDictionry = {
    UP: "UP",
    TRANSITION: "TRANSITION",
    DOWN: "DOWN",
    DRILL: 'DRILL',
    RESERVE_TEST: 'RESERVE_TEST'
};

export const dictionaryToOptions = Object.entries(dictionary).map(
    ([key, value]) => ({ key, value })
);

// export const TypesToPeriodDate: (keyof OpertaionTypeDictionry)[] = ['DRILL']
export const TypesToPeriodDate: string[] = ['DRILL']
