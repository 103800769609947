import { months } from "shared/constants/common";
import { getRates } from "entities/ISGuide/api";
import {
    ICreatingFormResponse,
    resourceDictionary,
    ResourceMode,
} from "entities/ISGuide/types";
import { TabsParams } from "../types";
import { ReactNode } from "react";

const radioMonthDictionary = months.map((el) => ({
    key: el.name.full,
    value: String(el.value),
}));

const subFilters = [
    {
        name: "month",
        itemsInLine: 4,
        items: radioMonthDictionary,
    },
    {
        name: "quartal",
        items: [
            {
                key: "1",
                value: "first",
            },
            {
                key: "2",
                value: "second",
            },
            {
                key: "3",
                value: "third",
            },
            {
                key: "4",
                value: "fourth",
            },
        ],
    },
    {
        name: "year",
        itemsInLine: 4,
        items: [
            { key: "2020", value: "2020" },
            { key: "2021", value: "2021" },
            { key: "2022", value: "2022" },
            { key: "2023", value: "2023" },
            { key: "2024", value: "2024" },
        ],
    },
];

export const getReportItems = (filter?: { [key: string]: string }) => {
    const triggerFilterValue = filter?.settings ?? "";
    const subFilter = subFilters.find(
        ({ name }) => name.toLowerCase() === triggerFilterValue?.toLowerCase()
    );
    return [
        // {
        //     name: "energy",
        //     items: [
        //         { key: "Тепло", value: "heat" },
        //         { key: "Эл. энергия", value: "electric" },
        //         { key: "Топливо", value: "fuel" },
        //         { key: "Сж. воздух", value: "compressed" },
        //     ],
        // },
        {
            name: "settings",
            items: [
                {
                    key: "Месяц",
                    value: "Month",
                },
                {
                    key: "Квартал",
                    value: "Quartal",
                },
                {
                    key: "Год",
                    value: "Year",
                },
            ],
        },
        subFilter ?? subFilters[0],
    ];
};

const reportColumn = [
    {
        title: "Дата",
        dataIndex: "date",
    },
    {
        title: "Наименование",
        dataIndex: "name",
    },
    {
        title: "Статус",
        dataIndex: "status",
    },
    {
        title: "Отправ",
        dataIndex: "sended",
    },
    {
        title: "Действия",
        dataIndex: "actions",
    },
];

const columns = (resources: ICreatingFormResponse[]) => [
    {
        title: "Наименование",
        dataIndex: "name",
        render(_: any, record: { StartDate: string; EndDate: string }) {
            return (
                <span>
                    {[record.StartDate, record.EndDate]
                        .map((el) => el.split("-")[0])
                        .join("-")}
                </span>
            );
        },
    },
    {
        title: "Ресурс",
        dataIndex: "EnergySourceId",
        render(_: any, record: { EnergySourceId: number }) {
            const actualResource = resources.find(
                (r) => r.EnergySourceId == record.EnergySourceId
            );
            return (
                <span>{actualResource?.EnergySourceName ?? "Неизвестно"}</span>
            );
        },
    },
    {
        title: "Статус",
        dataIndex: "Mode",
        render(_: any, record: { Mode: ResourceMode }) {
            return <span>{resourceDictionary[record.Mode].full}</span>;
        },
    },

    {
        title: "Действия",
        dataIndex: "actions",
    },
];

export const getColumnsApiDictionary: (
    resources: ICreatingFormResponse[]
) => Record<
    TabsParams,
    {
        columns: {
            title: string;
            dataIndex: string;
            render?: (...params: any) => ReactNode;
        }[];
        api: (params?: { [key: string]: string }) => Promise<any[]>;
    }
> = (resources) => ({
    balance: { columns: reportColumn, api: async () => [] },
    guide: { columns: columns(resources), api: getRates },
});
