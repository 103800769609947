import React, { FC } from "react";
import {
    IPeriodModifier,
    IEventPosition,
} from "services/pointEvents/pointEventsSlice";
import {
    changePeriod,
    PeriodWrapper,
    PeriodTransitionWrapper,
} from "../../PeriodWrapper";
import { ICellMapping } from "entities/MappingPositionsCell/Types/mapPositions";
import PeriodHeader from "../PeriodHeader";
import { Input } from "antd";
import { TypesToPeriodDate } from "entities/Types/helpers";
import { IEventType } from "entities/Types/types";

type Props = {
    periods: IPeriodModifier[];
    currentType: IEventType | null;
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
    updatePeriod: (id: number, period: IPeriodModifier) => void;
    cellMappings: ICellMapping[][];
    currentPosition: IEventPosition[];
};

const SimplePeriods: FC<Props> = (props) => {
    const {
        periods,
        beginDate,
        setPeriods,
        currentPosition,
        updatePeriod,
        cellMappings,
        currentType,
    } = props;

    return (
        <div>
            {periods?.map((period, i) => (
                <div className="period-container" key={"period-" + i}>
                    {cellMappings && (
                        <PeriodHeader
                            key={i}
                            cellMappings={cellMappings}
                            id={i}
                            updatePeriod={updatePeriod}
                            period={period}
                        />
                    )}
                    {React.createElement(
                        TypesToPeriodDate.includes(
                            currentType?.DraftSupplyPointEventOperationType ??
                                ""
                        )
                            ? PeriodWrapper
                            : PeriodTransitionWrapper,
                        { period, setPeriods, beginDate, currentPosition, i }
                    )}
                    <div className="modificator-container">
                        {currentType?.DraftSupplyPointEventOperationType ===
                            "DOWN" && <span>-</span>}
                        <Input
                            type="text"
                            value={period?.Value?.[0]}
                            placeholder="Модификатор"
                            onChange={(e) =>
                                changePeriod(
                                    setPeriods,
                                    beginDate,
                                    currentPosition,
                                    e.target.value,
                                    i,
                                    "Value"
                                )
                            }
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SimplePeriods;
