import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { IModalProps } from "shared/types/modal";
import styles from "./styles.module.css";
import ModalHeader from "./components/ModalHeader";

const Modal: FC<PropsWithChildren<IModalProps>> = (props) => {
    const { children, closedOnAway, title, description, showed, closeShowed } =
        props;

    return (
        <div
            className={classNames(styles["modal-background"], {
                [styles.none]: !showed,
            })}
            onClick={() => {
                closedOnAway && closeShowed();
            }}
        >
            <div
                className={styles["modal-body"]}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles["scroll-container"]}>
                    <ModalHeader
                        title={title}
                        description={description}
                        closeShowed={closeShowed}
                    />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
