import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { LoadingOutlined } from "@ant-design/icons";

const BigLoader = () => {
    const ref = useRef<HTMLInputElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!ref.current) return;
        const rect = ref.current.getBoundingClientRect();
        setHeight(document.body.clientHeight - rect.top - 50);
    }, [ref]);

    return (
        <div ref={ref} className={styles.loader} style={{ height }}>
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        style={{
                            fontSize: 48,
                        }}
                    />
                }
                size="large"
            />
        </div>
    );
};

export default BigLoader;
