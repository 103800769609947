import { useAppSelector, useActions, useDebounce } from "app/hooks";
import { Checkbox, Flex } from "antd";
import React, { useEffect, useState, useTransition } from "react";
import { SearchInput } from "shared/components";

const TableSeracher = () => {
    const { filteredString, showHiddenValues } = useAppSelector(
        (state) => state.Excel
    );
    const { setFilterValue, changeShowHiddenValuesStatus } = useActions();

    const [data, setData] = useState(filteredString);
    const value = useDebounce<string>(data, 600);
    const [_, startTransition] = useTransition();

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        // setData(e.target.value)
        startTransition(() => setData(e.target.value));
    }

    useEffect(() => {
        if (value !== filteredString) {
            setFilterValue(value);
        }
    }, [value]);

    return (
        <Flex align="flex-end" gap={10}>
            <SearchInput
                className="search"
                compacted
                height={55}
                width={150}
                allowClear
                style={{ width: 395, height: 55 }}
                placeholder="Умный поиск"
                onChange={onChange}
            />

            <Checkbox
                style={{ userSelect: "none" }}
                checked={showHiddenValues}
                onChange={() => changeShowHiddenValuesStatus()}
            >
                Показать скрытые значения
            </Checkbox>
        </Flex>
    );
};

export default TableSeracher;
