import React, { FC, useEffect, useMemo, useState } from "react";
import { ICellMapping } from "entities/MappingPositionsCell/Types/mapPositions";
import {
    IPeriodModifier,
    IEventPosition,
} from "services/pointEvents/pointEventsSlice";
import PeriodHeader from "../PeriodHeader";
import { PeriodTransitionWrapper } from "../../PeriodWrapper";
import { Input } from "antd";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import { getCellMappings } from "entities/MappingPositionsCell/api/mapTable";

type Props = {
    periods: IPeriodModifier[];
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
    updatePeriod: (id: number, period: IPeriodModifier) => void;
};

type SingleProps = {
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
    updatePeriod: (id: number, period: IPeriodModifier) => void;
    index: number;
    period: IPeriodModifier;
};

const ReserveTestPeriod: FC<SingleProps> = (props) => {
    const { setPeriods, beginDate, updatePeriod, index, period } = props;

    const { availablePositions } = usePointEventsContext();

    async function getMappings(
        position: IEventPosition
    ): Promise<ICellMapping | undefined> {
        const mappings = await getCellMappings({
            targetSupplyPointPositionId: position.SupplyPointMappingId,
        });
        const defaultItem = mappings.find(({ IsDefault }) => IsDefault);
        setLocalMappings([mappings]);
        return defaultItem;
    }

    async function updatePosition(position: IEventPosition) {
        const defaultItem = await getMappings(position);
        setCurrentPosition([position]);
        updatePeriod(index, {
            ...period,
            Position: [position.Position],
            CellMappingId: defaultItem?.Id ?? 0,
            Value: [defaultItem?.InstCapacity ?? 0, 0],
        });
    }

    const [localMappings, setLocalMappings] = useState<ICellMapping[][]>([[]]);
    const currentMappingObjectReserveValue = useMemo<number>(() => {
        const founder = localMappings[0].find(
            (el) => el.Id === period.CellMappingId
        );
        if (!founder?.ReserveTestAdditionalRatio) return 0;
        return founder.ReserveTestAdditionalRatio / 100;
    }, [period.CellMappingId, localMappings]);

    const [currentPosition, setCurrentPosition] = useState<IEventPosition[]>(
        () => {
            const result = availablePositions.find(
                ({ Position }) => Position === period.Position[0]
            );
            return result ? [result] : [];
        }
    );

    useEffect(() => {
        if (currentPosition.length) {
            getMappings(currentPosition[0]);
            return;
        }
        let result: IEventPosition | undefined;
        if (period.Position.length) {
            result = availablePositions.find(
                ({ Position }) => Position === period.Position[0]
            );
        }
        if (!result) {
            result = availablePositions[0];
        }
        updatePosition(result);
    }, []);

    return (
        <div className="period-container" key={"period-" + index}>
            <PeriodHeader
                key={index}
                cellMappings={localMappings}
                id={index}
                updatePeriod={updatePeriod}
                period={period}
                positionId={currentPosition?.[0]?.Id ?? null}
                updatePosition={updatePosition}
            />
            <PeriodTransitionWrapper
                period={period}
                setPeriods={setPeriods}
                beginDate={beginDate}
                i={index}
                currentPosition={currentPosition ?? []}
            />
            <div className="modificator-container">
                <Input
                    type="text"
                    disabled
                    value={
                        period?.Value?.[0] * currentMappingObjectReserveValue
                    }
                    placeholder="Модификатор"
                />
            </div>
        </div>
    );
};

const ReserveTestPeriods: FC<Props> = (props) => {
    const { periods, ...rest } = props;

    return (
        <div>
            {periods.map((period, id) => (
                <ReserveTestPeriod
                    period={period}
                    key={id}
                    index={id}
                    {...rest}
                />
            ))}
        </div>
    );
};

export default ReserveTestPeriods;
