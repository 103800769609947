import { RestInstanse } from "app/axiosInstance";
import { IMappingCellPosition } from "entities/MappingPositionsCell/Types/positions";
import { getAuth } from "helpers/getAuth";
import { IEventObject } from "services/pointEvents/pointEventsSlice";

export async function getObjects() {
    try {
        const response = await RestInstanse.get<IEventObject[]>(
            `/user-supply-point-position-mapping`,
            { ...getAuth() }
        );
        const filtered = [
            ...new Map(
                response.data.map((item) => [item.SupplyPointName, item])
            ).values(),
        ];
        return filtered;
    } catch (err) {
        return [];
    }
}

export const getPositions = async () => {
    try {
        const response = await RestInstanse.get<IMappingCellPosition[]>(
            "/user-supply-point-position-mapping",
            getAuth()
        );

        const { data } = response;
        const result = data.reduce<IMappingCellPosition[]>(
            (acc: IMappingCellPosition[], cur: IMappingCellPosition) => {
                if (
                    acc.some(
                        (item) =>
                            item.SupplyPointMappingId ===
                            cur.SupplyPointMappingId
                    )
                ) {
                    return acc;
                }
                return [...acc, cur];
            },
            []
        );
        return result;
    } catch (error) {
        console.error(error);
    }
};
