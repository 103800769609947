import { useNavigate } from 'react-router-dom';

const LogoSvg = () => {
  const navigate = useNavigate()
  const goRolePage = () => {
		return localStorage.getItem('role') === 'lineman' ? navigate('/point-events-lineman') :
		localStorage.getItem('role') === 'acceptor' ? navigate('/point-events-acceptor') : null
	}
  return (
    <div className='logo-wrapper' style={{display: 'flex'}} onClick={goRolePage}>
      <svg xmlns="http://www.w3.org/2000/svg" width="125" height="25" viewBox="0 0 125 25" fill="none">
      <path d="M29.4334 2.6893H34.773V17.0755H40.4935V21.6189H29.4334V2.6893Z" fill="#231F20"/>
      <path d="M42.6408 13.9653V2.68893H47.9803V13.8568C47.9803 15.0204 48.1987 17.6148 51.0311 17.6148C53.8659 17.6148 54.0843 15.0204 54.0843 13.8568V2.68893H59.4239V13.9653C59.4239 15.9937 58.987 22.1582 51.0311 22.1582C43.0776 22.1582 42.6408 15.9937 42.6408 13.9653Z" fill="#231F20"/>
      <path d="M62.9047 2.6893H68.2443V10.6389L73.9939 2.68922H80.2606L73.0135 11.8554L80.4499 21.6189H73.7755L68.2443 13.4791V21.6189H62.9047V2.6893ZM104.686 2.68843H110.026V21.618H104.686V2.68843ZM113.937 2.6893H119.277V17.0755H125V21.6189H113.937V2.6893Z" fill="#231F20"/>
      <path d="M95.9788 12.1672C95.9788 8.43086 93.6633 6.57836 91.1271 6.57836C88.5908 6.57836 86.2754 8.43086 86.2754 12.1672C86.2754 15.9034 88.5908 17.756 91.1271 17.756C93.6633 17.756 95.9788 15.9034 95.9788 12.1672ZM80.6446 12.1672C80.6446 6.99272 83.9915 1.92909 91.1271 1.92909C98.265 1.92909 101.61 6.99272 101.61 12.1672C101.61 17.3416 98.265 22.4052 91.1271 22.4052C83.9915 22.4052 80.6446 17.3416 80.6446 12.1672Z" fill="#231F20"/>
      <path d="M0 1C0 0.447716 0.447715 0 1 0H24.1346C24.6869 0 25.1346 0.447715 25.1346 1V24C25.1346 24.5523 24.6869 25 24.1346 25H1C0.447715 25 0 24.5523 0 24V1Z" fill="#E22739"/>
      <path d="M22.3542 4.74449L19.5315 9.2757C19.2233 9.78399 19.2694 10.1838 19.4126 10.5693L23.6988 21.453H5.94728C4.35513 21.453 3.1222 20.1835 3.1222 18.6707L3.1052 4.18313C3.14889 2.31137 5.89631 2.28007 5.94728 4.17592V17.7553C5.94243 18.1865 6.32348 18.6707 6.82588 18.6586H19.8276L16.4613 10.7957C16.3739 10.4946 16.3133 10.196 16.3133 9.89485C16.3133 9.56241 16.4613 9.03003 16.6822 8.59642L19.7718 3.54722C19.9999 3.09434 20.4611 2.78831 21.0096 2.76663C21.779 2.73291 22.4367 3.2581 22.4707 4.06992C22.4731 4.23132 22.4343 4.5855 22.3542 4.74449Z" fill="white"/>
      <path d="M12.5632 13.8941V4.17635C12.5632 2.2805 15.3495 2.26604 15.381 4.17635V14.0001C15.381 16.2476 13.6894 17.7339 11.6166 17.7339C9.54637 17.7339 7.84985 16.2115 7.84985 14.0001V4.17635C7.84985 2.2805 10.6677 2.2805 10.6677 4.17635V13.8941C10.6677 15.1973 12.5632 15.2768 12.5632 13.8941Z" fill="white"/>
      </svg>
  </div>
)};

export default LogoSvg;