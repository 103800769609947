import { FunctionComponent } from "react";
import { MonthSettings, QuartalSettings, YearSettings } from "ExcelTable/components/ExcelHeaderPanel/ExcelHeaderTitle/PeriodSettings/components";
import { PeriodVariants, PeriodFilter } from "../types";

export const periodConfiguration: {
    name: string;
    code: PeriodVariants;
    Value: FunctionComponent<{
        filters: PeriodFilter;
        updateFilter: (
            key: keyof PeriodFilter,
            value: PeriodFilter[keyof PeriodFilter]
        ) => void;
        isActive: boolean;
    }>;
}[] = [
    { name: "Месячный", Value: MonthSettings, code: "Month" },
    { name: "Квартальный", Value: QuartalSettings, code: "Quartal" },
    { name: "Годовой", Value: YearSettings, code: "Year" },
];
