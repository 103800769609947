import { Select } from "antd";
import React, { FC } from "react";
import { OnePositionSelect } from "entities/PointEvents/components";
import {
    IEventObject,
    IEventPosition,
} from "services/pointEvents/pointEventsSlice";
import { RedLine } from "shared/components";

const { Option } = Select;

type Props = {
    isTransition: boolean;
    currentObject: IEventObject | null;
    setCurrentPosition: (val: IEventPosition[]) => void;
    availablePositions: IEventPosition[];
    currentPosition: IEventPosition[];
    isHidden: boolean;
};

const PeriodPositionSelect: FC<Props> = (props) => {
    const {
        isHidden,
        isTransition,
        currentObject,
        setCurrentPosition,
        availablePositions,
        currentPosition,
    } = props;

    const updateCurrentPosition = (val: number, position?: number) => {
        if (currentObject === null) return;
        const resultPosition = availablePositions.find(({ Id }) => Id === val);
        if (!resultPosition) return;
        if (position) {
            const copy = currentPosition;
            copy.splice(position, 1, resultPosition);
            setCurrentPosition(copy);
            return;
        }
        setCurrentPosition([resultPosition]);
    };

    return (
        <div style={{ display: isHidden ? "none" : "block" }}>
            {!isTransition ? (
                <OnePositionSelect
                    disabled={!currentObject?.SupplyPointId}
                    availablePositions={availablePositions}
                    value={currentPosition?.[0]?.Id}
                    updateCurrentPosition={(val: number) =>
                        updateCurrentPosition(val)
                    }
                />
            ) : (
                <>
                    <div className="pair-wrapper">
                        <div className="pair">
                            с
                            <Select
                                disabled={!currentObject?.SupplyPointId}
                                placeholder="Позиция 1"
                                notFoundContent="Не найдено"
                                className="editable-select"
                                onChange={(val: number) =>
                                    updateCurrentPosition(val, 0)
                                }
                                value={currentPosition?.[0]?.Id}
                            >
                                {availablePositions.map((pos) => (
                                    <Option value={pos.Id} key={pos.Id}>
                                        {pos.Position} (
                                        {pos.MappedSupplyPointName})
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <RedLine />
                        <div className="pair">
                            на
                            <Select
                                className="editable-select"
                                notFoundContent="Не найдено"
                                disabled={!currentObject?.SupplyPointId}
                                onChange={(val: number) =>
                                    updateCurrentPosition(val, 1)
                                }
                                value={currentPosition?.[1]?.Id}
                            >
                                {availablePositions.map((pos) => (
                                    <Option value={pos.Id} key={pos.Id}>
                                        {pos.Position} (
                                        {pos.MappedSupplyPointName})
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PeriodPositionSelect;
