import { FC, useEffect, useMemo } from "react";
import { IConfig } from "../../utils/types";
import { quartlas, years } from "../../utils";
import { FlexInLine } from "shared/components";
import { Flex, Select } from "antd";
import { PeriodFilter } from "entities/ExcelTable/types";

type Props = {
    filters: PeriodFilter;
    updateFilter: (
        key: keyof PeriodFilter,
        value: PeriodFilter[keyof PeriodFilter]
    ) => void;
    isActive: boolean;
};

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();

const quartalLimits = [0, 3, 6, 9];
const index = quartalLimits.indexOf(
    [...quartalLimits].reverse().find((el) => el <= currentMonth) ?? 9
);
const availableQuartalsInCurrentYear = quartlas.slice(0, index + 1);

const QuartalSettings: FC<Props> = (props) => {
    const { filters, updateFilter, isActive } = props;

    const quartalConfig = useMemo<IConfig[]>(() => {
        const quartalPeriods: IConfig[] = [
            { title: "Год", options: years, name: "year" },
            {
                title: "Квартал",
                options:
                    filters.year < currentYear
                        ? quartlas
                        : availableQuartalsInCurrentYear,
                name: "period",
            },
        ];
        return quartalPeriods;
    }, [filters.year]);

    useEffect(() => {
        if (
            isActive &&
            quartalConfig.every((el) => el.name !== filters.period)
        ) {
            updateFilter("period", quartalConfig.at(-1)?.options[0].value!);
        }
    }, [quartalConfig, isActive]);

    return (
        <FlexInLine gap={15}>
            {quartalConfig.map(({ title, options, name }) => (
                <Flex vertical gap={5} key={name}>
                    <label htmlFor={name}>{title}</label>
                    <Select
                        onChange={(val) => updateFilter(name, val)}
                        id={name}
                        value={filters[name]}
                        options={options.map((el) => ({
                            value: el.value,
                            label: <span>{el.name}</span>,
                        }))}
                    />
                </Flex>
            ))}
        </FlexInLine>
    );
};

export default QuartalSettings;
