import { useMemo, useState } from "react";
import {
    SortedState,
    SortedMode,
    ITableRow,
} from "entities/ExcelTable/types";
import { sortRowsByField } from "../methods";

const findField = (
    field: string,
    obj: { [key: string]: any }
): null | number => {
    const points = field.split(".");
    if (obj === null) return -Infinity;
    const objectslav = obj[points[0]];
    if (typeof objectslav !== "object") {
        return objectslav;
    }
    if (points.length > 1) {
        return findField(points.slice(1).join("."), obj[points[0]]);
    }
    return -Infinity;
};

export function useSortedRows(rows: ITableRow[]) {
    const [sorted, setSorted] = useState<SortedState>(() => {
        return {
            field: null,
            mode: "default",
        };
    });

    const sortedRows = useMemo<ITableRow[]>(() => {
        return sortRowsByField([...rows], sorted);
    }, [rows, sorted]);

    const generateSortMode = (field: string) => {
        let value: SortedMode = "top";
        if (sorted.field === field) {
            value =
                sorted.mode === "top"
                    ? "bottom"
                    : sorted.mode === "bottom"
                    ? "default"
                    : "top";
        }
        setSorted((prev) => ({
            ...prev,
            field: value === "default" ? null : field,
            mode: value,
        }));
    };

    return { rows: sortedRows, toggleSorting: generateSortMode, sorted };
}
