import { useAppDispatch, useAppSelector } from "app/hooks";

import { useEffect } from "react";
import { fetchResources } from "entities/ExcelTable/slice";
import { Outlet } from "react-router-dom";

const ISEnergyMainContainer = () => {
    const dispatch = useAppDispatch();
    const { resources } = useAppSelector((state) => state.Excel);

    useEffect(() => {
        if (!resources.length) {
            dispatch(fetchResources());
        }
    }, [resources]);

    return <Outlet />;
};

export default ISEnergyMainContainer;
