import { useState } from "react";

function isInclude(key: number, home: number | number[]): boolean {
    if (Array.isArray(home)) {
        return home.includes(key);
    }
    return home === key;
}

export const useUpdateArray = <T extends { key: number }>(
    defaultData?: T[]
): [
    T[],
    {
        update: (key: number, name: keyof T, value: T[keyof T]) => void;
        toggle: (elem: T | T[], key?: any, value?: any) => void;
        reset: () => void;
    }
] => {
    function updateElem(
        key: number | number[],
        name: keyof T,
        value: T[keyof T]
    ) {
        setData((prev) =>
            prev.map((el) =>
                isInclude(el.key, key) ? { ...el, [name]: value } : el
            )
        );
    }

    function toggleElem(elem: T | T[], key?: any, value?: any) {
        const arr = Array.isArray(elem) ? elem : [elem];
        setData((prev) => {
            let clone = [...prev];
            arr.forEach((el) => {
                const elemIndex = data.findIndex((item) => item.key === el.key);
                if (elemIndex > -1) {
                    clone.splice(elemIndex, 1);
                } else {
                    const newElem = { ...el };
                    if (key && value) {
                        newElem[key as keyof T] = value;
                    }
                    clone.push(newElem);
                }
            });
            return clone;
        });
    }

    function cleanArray() {
        setData([]);
    }

    const [data, setData] = useState<T[]>(defaultData ?? []);

    return [
        data,
        { toggle: toggleElem, update: updateElem, reset: cleanArray },
    ];
};
