import { Modal, Radio } from "antd";
import { FC, useEffect, useState } from "react";
import { HiddenContainer } from "ExcelTable/shared/components";
import ExcelColorsSettings from "./ExcelColorSettings";
import { ColorPallete } from "entities/ExcelTable/types";

type Props = {
    colors: ColorPallete;
    changeColor: (key: keyof ColorPallete, color: string) => void;
    isOpen: boolean;
    onClose: () => void;
};

const tabs = ["colors"] as const;
export type Tabs = (typeof tabs)[number];

const SettingsContent: FC<Props> = (props) => {
    const { colors, changeColor, isOpen, onClose } = props;
    const [settings, setSettings] = useState<Tabs>("colors");

    useEffect(() => {
        if (settings !== "colors") {
            setSettings("colors");
        }
    }, []);

    return (
        <Modal
            width={1200}
            footer={<></>}
            open={isOpen}
            onCancel={onClose}
            onClose={onClose}
        >
            <Radio.Group
                onChange={(e) => setSettings(e.target.value)}
                value={settings}
                style={{
                    marginBottom: 8,
                }}
            >
                <Radio.Button value="colors">Цвета</Radio.Button>
            </Radio.Group>
            <HiddenContainer isHidden={settings !== "colors"}>
                <ExcelColorsSettings
                    colors={colors}
                    changeColor={changeColor}
                    onClose={onClose}
                />
            </HiddenContainer>
        </Modal>
    );
};

export default SettingsContent;
