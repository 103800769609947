import { RestInstanse } from "../../app/axiosInstance";
import { IEventPosition } from "./pointEventsSlice";
import { getAuth } from "helpers/getAuth";

export async function getPositionsByObjectId(params?: {
    supplyPointId?: number;
}): Promise<IEventPosition[]> {
    try {
        if (!params?.supplyPointId) return [];
        const response = await RestInstanse.get<IEventPosition[]>(
            `/user-supply-point-position-mapping/by-user-and-sp`,
            { ...getAuth(), params }
        );
        const { data } = response;
        const result = data.reduce<IEventPosition[]>(
            (acc: IEventPosition[], cur: IEventPosition) => {
                if (
                    acc.some(
                        (item) =>
                            item.SupplyPointMappingId ===
                            cur.SupplyPointMappingId
                    )
                )
                    return acc;
                return [...acc, cur];
            },
            []
        );

        return result;
    } catch (err) {
        return [];
    }
}
