import { FC, useEffect, useMemo, useState } from "react";
import ExcelColumnCell from "./ExcelColumnCell";
import ExcelNameCell from "./ExcelNameCell";
import { useTableBodyContext, useTableContext } from "../context";
import { useEffectSkipFirstRender } from "hooks";
import styles from "./styles.module.css";
import classNames from "classnames";
import { hasSubstring, sortRowsByField } from "../helpers/methods";
import { useAppSelector } from "app/hooks";
import { ITableColumn, ITableRow } from "entities/ExcelTable/types";

type Props = {
    item: ITableRow;
    columns: ITableColumn[];
    order?: number;
    none?: boolean;
    dataIndex: string;
    lineConfig?: number[];
};

const ExcelRow: FC<Props> = (props) => {
    const { item, columns, order, none, dataIndex, lineConfig } = props;

    const hasChildren = !!item.Children?.length;

    const { allClose, allOpen, sorted } = useTableContext();
    const { actualEditable } = useTableBodyContext();

    const { filteredString, pointedRows } = useAppSelector(
        (state) => state.Excel
    );

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (none && isOpen) {
            setIsOpen(false);
        }
    }, [none]);

    const hasFilter = useMemo(() => {
        return (
            filteredString.length === 0 || hasSubstring(item, filteredString)
        );
    }, [filteredString]);

    useEffect(() => {
        setIsOpen(filteredString.length > 0);
    }, [filteredString]);

    const lineArr = useMemo<number[]>(() => {
        const arr = lineConfig?.length ? [...lineConfig] : [];
        if (isOpen) arr.push(order ?? 0);
        return arr;
    }, [lineConfig, isOpen]);

    useEffect(() => {
        if (actualEditable?.includes(dataIndex.split(":")[0] + "."))
            setIsOpen(true);
    }, [actualEditable]);

    useEffectSkipFirstRender(() => {
        if (hasChildren) {
            setIsOpen(true);
        }
    }, allOpen);

    useEffectSkipFirstRender(
        () => {
            setIsOpen(false);
        },
        allClose,
        sorted
    );

    const sortedRows = useMemo<ITableRow[]>(() => {
        if (!item.Children?.length) return [];
        return sortRowsByField([...item.Children], sorted);
    }, [sorted, item]);

    const isPicked = useMemo<boolean>(() => {
        return pointedRows.includes(item.Id);
    }, [item, pointedRows]);

    return (
        <>
            <tr
                data-row={dataIndex}
                data-status={hasChildren ? "parent" : "child"}
                className={classNames(styles["excel-row"], {
                    [styles.none]: none || !hasFilter,
                    [styles.bold]: hasChildren,
                })}
            >
                {none !== true && (
                    <>
                        <ExcelNameCell
                            dataIndex={`${dataIndex}-col-1`}
                            order={order}
                            row={item}
                            isPicked={isPicked}
                            isOpen={isOpen}
                            lineConfig={lineArr}
                            setOpen={() => setIsOpen((prev) => !prev)}
                        />
                        {columns.slice(1).map((col, id) => (
                            <ExcelColumnCell
                                dataIndex={`${dataIndex}:${col.Id}`}
                                key={id}
                                isPicked={isPicked}
                                blocked={hasChildren}
                                ariaColindex={id}
                                subColumns={col.Children}
                                value={item.Cells}
                                columnId={col.Id}
                            />
                        ))}
                    </>
                )}
                {/* <ExcelNameCell
                    dataIndex={`${dataIndex}-col-1`}
                    order={order}
                    value={columns[0].Name}
                    children={item.Children}
                    isOpen={isOpen}
                    lineConfig={lineArr}
                    setOpen={() => setIsOpen((prev) => !prev)}
                />
                {columns.slice(1).map((col, id) => (
                    <ExcelColumnCell
                        dataIndex={`${dataIndex}:${col.Id}`}
                        key={id}
                        ariaColindex={id}
                        subColumns={col.Children}
                        value={item.Cells}
                        columnId={col.Id}
                    />
                ))} */}
            </tr>
            {!!sortedRows.length &&
                sortedRows.map((child, id) => (
                    <ExcelRow
                        dataIndex={`${dataIndex}.${id + 1}`}
                        key={id}
                        lineConfig={lineArr}
                        order={(order || 0) + 1}
                        item={child}
                        columns={columns}
                        none={!isOpen}
                    />
                ))}
        </>
    );
};

export default ExcelRow;
