import { useDebounce } from "app/hooks";
import { Flex } from "antd";
import React, { FC } from "react";
import { useEffectSkipFirstRender, useInput } from "hooks";
import { SearchInput } from "shared/components";
import RedButton from "shared/components/RedButton";

type Props = {
    updateString: (val: string) => void;
    callCreatingTemplate: () => void;
};

const GuideTemplateHeader: FC<Props> = React.memo((props) => {
    const { updateString, callCreatingTemplate } = props;
    const [data, setData] = useInput("");
    const value = useDebounce<string>(data, 600);

    useEffectSkipFirstRender(() => {
        updateString(value);
    }, [value]);

    return (
        <Flex align="flex-end" gap={15}>
            <SearchInput
                className="search"
                compacted
                height={55}
                width={150}
                allowClear
                style={{ width: 395, height: 55 }}
                placeholder="Умный поиск"
                value={data}
                onChange={setData}
            />

            <RedButton onClick={callCreatingTemplate}>
                Добавить шаблон
            </RedButton>
        </Flex>
    );
});

export default GuideTemplateHeader;
