import { FC } from "react";
import styles from "./styles.module.css";
import RadioTabs from "shared/components/RadioTabs";
import { CreatingForm, TemplateMap } from "../../types";

type Props = {
    value: TemplateMap;
    changeValue: (name: string, key: string, value: string) => void;
    element: CreatingForm;
};

const GuideTemplateEnergy: FC<Props> = (props) => {
    const { value, changeValue, element } = props;
    const { title, value: name, items } = element;

    return (
        <div className={styles.container}>
            <h6 className={styles["template-title"]}>{title}</h6>
            {items.map((el) => (
                <div className={styles["tab-row"]} key={el.name}>
                    {el.title && (
                        <p className={styles["tab-title"]}>{el.title}</p>
                    )}
                    <RadioTabs
                        key={el.name}
                        value={value[name]?.[el.name] ?? ""}
                        itemsinLine={el.itemsInLine}
                        values={el.items}
                        changeString={(val: string) =>
                            changeValue(name, el.name, val)
                        }
                    />
                </div>
            ))}
        </div>
    );
};

export default GuideTemplateEnergy;
