import { Flex } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "hooks";
import { BigLoader } from "components/UI";
import {
    ChildrenCell,
    IResource,
    ITemplate,
    Template,
    TemplateResponse,
} from "entities/ISGuide/types";
import {
    GuideTemlateListHeader,
    GuideTemplateHeader,
    GuideTemplateListItem,
} from "entities/ISGuide/components";
import { getTemplates } from "entities/ISGuide/api";
import TemplateModal from "widgets/ISGuide/TemplateModal";
import styles from "./styles.module.css";
import { useGuideChildrenCells } from "entities/ISGuide/helpers";
import { useAppSelector } from "app/hooks";
import { useFirstLoading } from "hooks/useFirstLoading";

const TemplateGuidePage = () => {
    const [data, setData] = useState<string>("");

    const onUpdateString = useCallback(
        (val: string) => {
            setData(val);
        },
        [setData]
    );

    const [activeTemplate, setActiveTemplate] = useState<
        Template | null | undefined
    >(undefined);

    const onCreateTemplate = useCallback(() => {
        setActiveTemplate(null);
    }, [setActiveTemplate]);

    const { guideCreatingForm } = useAppSelector((state) => state.Excel);

    const {
        data: templates,
        trigger,
        isLoading,
        manuallyEdit,
    } = useFetch<ITemplate[], undefined>(getTemplates);

    const {
        isAllClosed,
        toggleChildren,
        replaceGlobalStatus,
        globalOpenStatus,
    } = useGuideChildrenCells(templates);

    const allResources = useMemo(() => {
        if (!guideCreatingForm.form.length) return {};
        return guideCreatingForm.subFilters.reduce<
            Record<string, { value: string; name: string }>
        >((acc, cur) => {
            cur.items.forEach((el) => {
                const axisName = guideCreatingForm.form[0].items.find(
                    (it) => it.value === cur.name
                )!;
                acc[el.value] = { value: el.key, name: axisName?.key ?? "" };
            });
            return acc;
        }, {});
    }, [guideCreatingForm]);

    const loading = useFirstLoading(isLoading);

    return (
        <Flex vertical gap={25} className={styles.container}>
            <GuideTemplateHeader
                updateString={onUpdateString}
                callCreatingTemplate={onCreateTemplate}
            />

            <TemplateModal
                currentValue={activeTemplate}
                onCloseModal={(data?: TemplateResponse) => {
                    if (data) {
                        trigger();
                        // const entries = Object.entries(data);
                        // manuallyEdit(
                        //     (templates ?? []).map((el) => {
                        //         const elem = entries.find(
                        //             ([key, _]) => +key === el.PositionId
                        //         );
                        //         if (!elem) return el;
                        //         const maps = Object.values(elem[1].body).reduce(
                        //             (acc, cur) => {
                        //                 return { ...acc, ...cur };
                        //             },
                        //             {}
                        //         );
                        //        
                        //         const mapsEntries = Object.entries(maps);

                        //         const newMap: IResource[] = mapsEntries.map(
                        //             ([key, value]) => ({
                        //                 PositionId: el.PositionId,
                        //                 AxisName: allResources[key].name,
                        //                 EffectiveDate: elem[1].EffectiveDate,
                        //                 EnergySourceName:
                        //                     allResources[key].value,
                        //                 Mode: value,
                        //                 EnergySourceId: +key,
                        //             })
                        //         );
                        //         return { ...el, Map: newMap };
                        //     })
                        // );
                    }
                    setActiveTemplate(undefined);
                }}
            />

            <GuideTemlateListHeader
                closedStatus={isAllClosed}
                replaceStatus={replaceGlobalStatus}
            />

            <Flex vertical gap={10}>
                {loading ? (
                    <BigLoader />
                ) : (
                    (templates ?? []).map((el, id) => (
                        <GuideTemplateListItem
                            template={el}
                            globalOpenStatus={globalOpenStatus}
                            key={id}
                            dataFilter={data}
                            toggleChildren={toggleChildren}
                            onClick={setActiveTemplate}
                        />
                    ))
                )}
            </Flex>
        </Flex>
    );
};

export default TemplateGuidePage;
