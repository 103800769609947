export const crumbsDictionary: { [key: string]: string } = {
    positions: "Позиции",
    "point-events-lineman": "События",
    "supply-point-cell-mapping": "Ячейки по ОЛС",
    "mapping-cells": "Мэппинг",
    "mapping-object-users": "Доступы",
    "admin-side": "Администратор",
    "types-creating": "Типы событий",
    "is-energy": "ИС-Энергия",
    balance: "Энергобаланс",
    chinazes: "Чиназес, брат",
    guides: "Справочники",
    templates: "Шаблоны энергоресурсов",
    view: "Нормы",
    renovation: "Ремонтные работы",
    fuel: 'Топливо',
    compressed: 'Сжатый воздух',
    electric: "Электроэнергия",
    heat: 'Тепло'
};
