import { useEffect, useState } from "react";

export function useLocalStorage<T>(
    key: string,
    initialValue: T,
    onlyGet?: boolean
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [value, setValue] = useState<T>(() => {
        const jsonValue = localStorage.getItem(key);
        if (jsonValue === null) return initialValue;
        return JSON.parse(jsonValue);
    });

    useEffect(() => {
        setValue(() => {
            const jsonValue = localStorage.getItem(key);
            if (jsonValue === null) return initialValue;
            return JSON.parse(jsonValue);
        });
    }, [key]);

    useEffect(() => {
        if (!onlyGet) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [value, onlyGet]);

    return [value, setValue];
}
