import classNames from "classnames";
import React, { FC } from "react";
import styles from "./styles.module.css";

type Props = {
    className?: string;
};

const RedLine: FC<Props> = React.memo(({ className }) => {
    return (
        <div className={classNames(className, styles['red-container'])}>
            <div className={styles["red-circle"]} />{" "}
            <div className={styles["red-line"]} />{" "}
            <div className={styles["red-circle"]} />
        </div>
    );
});

export default RedLine;
