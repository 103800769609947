import { getAuth } from "helpers/getAuth";
import { RestInstanse } from "../../../app/axiosInstance";
import { IEventType } from "../types";

const BASE_URL = "/draft-supply-point-event-type";

export const getAllTypes = async () => {
    try {
        const { data } = await RestInstanse.get<IEventType[]>(
            BASE_URL,
            getAuth()
        );
        return data;
    } catch (err) {
        return [];
    }
};

export const deleteType = async (id: number) => {
    try {
        await RestInstanse.delete(`${BASE_URL}`, {
            ...getAuth(),
            params: { id },
        });
    } catch (err) {
        console.error(err);
    }
};

export const createType = async (type: Omit<IEventType, 'Id'>) => {
    try {
        await RestInstanse.post<IEventType>(BASE_URL, type, getAuth());
    } catch (err) {
        console.error(err);
    }
}