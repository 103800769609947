import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IFilterFabric } from "shared/types/filters";

export const useFilters = (filters: IFilterFabric[]) => {
    function updateFilters(key: string, value: string | null) {
        if (value === undefined) return;
        const newFilters = { ...filterValue, [key]: value };

        setFilterValue(newFilters);
        const notEmptyValues = Object.fromEntries(
            Object.entries(newFilters).filter(([_, value]) => !!value) as [string, string | null][]
        ) as Record<PropertyKey, string>;
        setSearchParams(notEmptyValues);
    }

    const [searchParams, setSearchParams] = useSearchParams();

    const [filterValue, setFilterValue] = useState<{[key: string]: string | null}>(() =>
        filters.reduce((acc, cur) => {
            return { ...acc, [cur.name]: searchParams.get(cur.name) ?? null };
        }, {})
    );

    return { filter: filterValue, updateFilters };
};
