import React, { useMemo, useState } from "react";
import "./styles.scss";
import {
    useCreateDraftSupplyPointEvent,
    useGetDraftSupplyPointEvents,
    useUpdateDraftSupplyPointEvent,
} from "hooks/pointEventsHook";
import {
    IEventObject,
    IEventPosition,
    IModifier,
    IPeriodModifier,
    IPointEvent,
} from "services/pointEvents/pointEventsSlice";
import moment from "moment";
import { DatePicker, message, Select } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import {
    PeriodAccepting,
    PeriodPositionSelect,
    PeriodsFabric,
} from "./components";
import { DisabledSpace } from "../UI";
import { Modal } from "shared/components";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import { EventPeriodType, IEventType } from "entities/Types/types";

type Props = {
    onClose: () => void;
    currentEvent: IPointEvent | undefined;
    searchPatternFilter: string;
    beginDateFilter?: string;
    endDateFilter?: string;
    supplyPointIdFilter?: number;
    eventSupplyPointMappingIdFilter?: number;
    role: "lineman" | "acceptor";
    availablePositions: IEventPosition[];
    setAvailablePositions: (objectId?: number) => Promise<IEventPosition[]>;
    availableObjects: IEventObject[];
    currentObject: IEventObject | null;
    setCurrentObject: React.Dispatch<
        React.SetStateAction<IEventObject | null | undefined>
    >;
};

const { Option } = Select;

const EventModal = ({
    onClose,
    currentEvent,
    searchPatternFilter,
    beginDateFilter,
    endDateFilter,
    supplyPointIdFilter,
    eventSupplyPointMappingIdFilter,
    role,
    availableObjects,
    availablePositions,
    setAvailablePositions,
    currentObject,
    setCurrentObject,
}: Props) => {
    const { availableTypes } = usePointEventsContext();

    const [currentType, setCurrentType] = useState<IEventType | null>(() => {
        if (!currentEvent) return null;
        const current = availableTypes?.find(
            (type) => type.Id === +currentEvent?.TypeId
        );
        return current ?? null;
    });

    const [currentPosition, setCurrentPosition] = useState<IEventPosition[]>(
        () => {
            if (!currentEvent) return [];
            const periodsUniq: Record<string, IPeriodModifier> = {};
            const currentPositions: IEventPosition[] = [];

            currentEvent.ModifierData?.forEach((modi) => {
                const dataSet = modi?.BeginDate + modi?.EndDate;
                if (periodsUniq[dataSet] === undefined) {
                    periodsUniq[dataSet] = {
                        BeginDate: modi?.BeginDate,
                        EndDate: modi?.EndDate,
                        Value: [modi?.Value],
                        Position: [modi?.Position],
                        CellMappingId: 0,
                    };
                } else {
                    periodsUniq[dataSet] = {
                        ...periodsUniq[dataSet],
                        Value: [...periodsUniq[dataSet]?.Value, modi?.Value],
                        Position: [
                            ...periodsUniq[dataSet]?.Position,
                            modi?.Position,
                        ],
                    };
                }
            });
            // setPeriods(Object.values(periodsUniq));
            for (const key in periodsUniq) {
                periodsUniq?.[key]?.Position?.forEach((posName) => {
                    if (currentObject?.SupplyPointId) {
                        const selectedPos = availablePositions.find(
                            (pos) => pos.Position === posName
                        );
                        selectedPos && currentPositions?.push(selectedPos);
                    }
                });
            }
            return currentPositions;
        }
    ); //две позиции для перехода, type=3

    const [beginDate, setBeginDate] = useState<string>(
        currentEvent
            ? moment(currentEvent?.BeginDate).format("DD.MM.YYYY")
            : moment.utc().add(5, "hours").format("DD.MM.YYYY")
    );
    const { refresh } = useGetDraftSupplyPointEvents({
        searchPattern: searchPatternFilter,
        beginDate: beginDateFilter,
        endDate: endDateFilter,
        supplyPointId: supplyPointIdFilter
            ? supplyPointIdFilter.toString()
            : undefined,
        eventSupplyPointMappingId: eventSupplyPointMappingIdFilter,
    });

    const { createPointEvent } = useCreateDraftSupplyPointEvent();
    const { updatePointEvent } = useUpdateDraftSupplyPointEvent();

    async function onChangeObject(id: number) {
        const item = availableObjects?.find(({ Id }) => Id === id);
        if (item) {
            setCurrentObject(item);
            const data = await setAvailablePositions(item.SupplyPointId);
            setCurrentPosition([data[0], data?.[1] ?? data[0]]);
        }
    }

    const mainEventType = useMemo<EventPeriodType>(() => {
        const type = currentType?.DraftSupplyPointEventOperationType;
        switch (type) {
            case "TRANSITION":
                return "transition";
            case "RESERVE_TEST":
                return "reverse";
            default:
                return "default";
        }
    }, [currentType]);

    const isTransition = mainEventType === "transition";

    const [periods, setPeriods] = useState<IPeriodModifier[]>([]);

    function onChangeType(id: number) {
        const current =
            availableTypes?.find((type) => type.Id === id) ?? undefined;
        if (
            currentType?.DraftSupplyPointEventOperationType !== "TRANSITION" &&
            current?.DraftSupplyPointEventOperationType === "TRANSITION" &&
            currentObject
        ) {
            const secondPosition = availablePositions?.find(
                (position) => position.Id !== currentPosition[0].Id
            );
            setCurrentPosition([
                currentPosition[0],
                secondPosition ?? currentPosition[0],
            ]);
        }
        setCurrentType(current ?? null);
    }

    const changeBeginDate = (e: dayjs.Dayjs) => {
        const newBeginDate = e.format("DD.MM.YYYY");
        setBeginDate(newBeginDate);
        newBeginDate &&
            setPeriods((prev) => {
                const newPeriods = [...prev];
                newPeriods?.map((period) => {
                    period.BeginDate =
                        newBeginDate
                            ?.split("T")?.[0]
                            ?.split(".")
                            ?.reverse()
                            ?.join("-") +
                        "T" +
                        period.BeginDate?.split("T")?.[1];
                    period.EndDate =
                        newBeginDate
                            ?.split("T")?.[0]
                            ?.split(".")
                            ?.reverse()
                            ?.join("-") +
                        "T" +
                        period.EndDate?.split("T")?.[1];
                });
                return [...newPeriods];
            });
    };

    const saveOrUpdateEvent = (periods: IPeriodModifier[]) => {
        if (
            periods?.[0].BeginDate !== "" &&
            periods?.[0].EndDate !== "" &&
            !!currentPosition
        ) {
            if (!!currentEvent) {
                if (currentType?.Id && currentObject?.SupplyPointId) {
                    if (!isTransition) {
                        const modifier: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                CellMappingId: el.CellMappingId,
                                Value:
                                    el?.Value?.[0] *
                                    (currentType.DraftSupplyPointEventOperationType ==
                                    "DOWN"
                                        ? -1
                                        : 1),
                                Position: el?.Position?.[0],
                            };
                        });
                        updatePointEvent({
                            pointEvent: {
                                ...(currentEvent as IPointEvent),
                                TypeId: currentType?.Id,
                                SupplyPointId: String(
                                    currentObject?.SupplyPointId
                                ),
                                BeginDate:
                                    beginDate.split(".").reverse().join("-") +
                                    "T00:00",
                                ModifierData: modifier,
                            },
                            onSuccess(data) {
                                refresh();

                                onClose();
                                // alert('Обновлено')
                            },
                        });
                    } else {
                        const modifierFrom: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                Value: -Math.abs(el?.Value?.[0]),
                                Position: el?.Position?.[0],
                                CellMappingId:
                                    (el.CellMappingId as number[])?.[0] ?? 0,
                            };
                        });
                        const modifierTo: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                Value: el?.Value?.[1],
                                Position: el?.Position?.[1],
                                CellMappingId:
                                    (el.CellMappingId as number[])?.[1] ?? 0,
                            };
                        });
                        updatePointEvent({
                            pointEvent: {
                                ...(currentEvent as IPointEvent),
                                TypeId: currentType?.Id,
                                SupplyPointId: String(
                                    currentObject?.SupplyPointId
                                ),
                                BeginDate:
                                    beginDate.split(".").reverse().join("-") +
                                    "T00:00",
                                ModifierData: [...modifierFrom, ...modifierTo],
                            },
                            onSuccess(data) {
                                refresh();

                                onClose();
                                // alert('Обновлено')
                            },
                        });
                    }
                }
            } else {
                //save
                if (currentType?.Id && currentObject?.SupplyPointId) {
                    if (!isTransition) {
                        const modifier: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                CellMappingId: el.CellMappingId,
                                Value: el?.Value?.[0],
                                Position: el?.Position?.[0],
                            };
                        });
                        createPointEvent({
                            pointEvent: {
                                TypeId: currentType?.Id,
                                SupplyPointId: String(
                                    currentObject?.SupplyPointId
                                ),
                                BeginDate:
                                    beginDate.split(".").reverse().join("-") +
                                    "T00:00",
                                ModifierData: modifier,
                            },
                            onSuccess(data) {
                                refresh();
                                onClose();
                                // alert('Сохранено')
                            },
                        });
                    } else {
                        const modifierFrom: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                Value: -Math.abs(el?.Value?.[0]),
                                Position: el?.Position?.[0],
                                CellMappingId:
                                    (el.CellMappingId as number[])?.[0] ?? 0,
                            };
                        });
                        const modifierTo: IModifier[] = periods?.map((el) => {
                            return {
                                ...el,
                                Value: el?.Value?.[1],
                                Position: el?.Position?.[1],
                                CellMappingId:
                                    (el.CellMappingId as number[])?.[1] ?? 0,
                            };
                        });
                        createPointEvent({
                            pointEvent: {
                                TypeId: currentType?.Id,
                                SupplyPointId: String(
                                    currentObject?.SupplyPointId
                                ),
                                BeginDate:
                                    beginDate.split(".").reverse().join("-") +
                                    "T00:00",
                                ModifierData: [...modifierFrom, ...modifierTo],
                            },
                            onSuccess(data) {
                                refresh();

                                onClose();
                                // alert('Сохранено')
                            },
                        });
                    }
                }
            }
        } else {
            message.error("Не все поля заполнены", 1);
        }
    };

    const currentTitle = useMemo(() => {
        const text = {
            title:
                (role === "acceptor"
                    ? "Одобрение"
                    : currentEvent
                    ? "Редактирование"
                    : "Создание") + " события",
            description:
                role === "lineman"
                    ? `Введите данные для ${
                          currentEvent ? "редактирования" : "создания"
                      } события и сохраните изменения`
                    : "Выберите одобрять или отклонить событие-кандидат",
        };
        return text;
    }, [role, currentEvent]);

    return (
        <Modal
            title={currentTitle.title}
            description={currentTitle.description}
            closeShowed={onClose}
            showed
        >
            {role === "acceptor" && currentEvent ? (
                <PeriodAccepting currentEvent={currentEvent} />
            ) : (
                <DisabledSpace
                    className="scroll-container"
                    isActive={!!currentEvent?.IsAccepted}
                    zIndex={10}
                >
                    <DatePicker
                        locale={locale}
                        value={dayjs(beginDate, "DD.MM.YYYY")}
                        format={"DD.MM.YYYY"}
                        onChange={changeBeginDate}
                        className="date-picker"
                    />
                    <Select
                        className="editable-select"
                        notFoundContent="Не найдено"
                        placeholder="Объект:"
                        onChange={onChangeObject}
                        value={currentObject?.Id}
                    >
                        {availableObjects?.map((object) => (
                            <Option value={object?.Id} key={object?.Id}>
                                {object?.SupplyPointName}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        className="editable-select"
                        notFoundContent="Не найдено"
                        placeholder="Тип:"
                        onChange={onChangeType}
                        value={currentType?.Id}
                    >
                        {availableTypes?.map((type) => (
                            <Option value={type?.Id} key={type?.Id}>
                                {type?.LocalName}
                            </Option>
                        ))}
                    </Select>
                    <PeriodPositionSelect
                        currentObject={currentObject}
                        availablePositions={availablePositions}
                        isTransition={isTransition}
                        setCurrentPosition={(data: IEventPosition[]) =>
                            setCurrentPosition(data)
                        }
                        isHidden={mainEventType === "reverse"}
                        currentPosition={currentPosition}
                    />
                    <PeriodsFabric
                        currentEvent={currentEvent}
                        role={role}
                        saveOrUpdateEvent={saveOrUpdateEvent}
                        currentPosition={currentPosition}
                        beginDate={beginDate}
                        currentType={currentType}
                        mainEventType={mainEventType}
                    />
                </DisabledSpace>
            )}
        </Modal>
    );
};

export default EventModal;
