import { useAppSelector } from "app/hooks";
import { Flex, Typography } from "antd";
import React, { FC } from "react";
import styles from "./styles.module.css";
import { withSkeleton } from "shared/hocs/withSkeleton";

type Props = {
    values: { key: string; value: string }[];
};

const CellHistoryTable: FC<Props> = (props) => {
    const { values } = props;

    return (
        <Flex vertical gap={12}>
            {values.map((el, index) => (
                <Flex
                    gap={10}
                    justify="space-between"
                    className={styles["table-item"]}
                    key={index}
                >
                    <strong>{el.key}</strong> <span>{el.value}</span>
                </Flex>
            ))}
        </Flex>
    );
};

export default withSkeleton<Props>(CellHistoryTable, 11)
