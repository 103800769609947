import { ApiModule } from "shared/ApiModule";
import {
    ICreatingFormResponse,
    IRatesParams,
    IResource,
    ITemplate,
    ITemplatePosition,
    ResourceMode,
    TemplateMap,
    TemplateResponse,
} from "../types";
import { IGuideRate } from "entities/ExcelTable/types";

export const getTemplates = async (): Promise<ITemplate[]> => {
    const response = await ApiModule.get<ITemplate[]>(
        "/SupplyPointPositionEnergySource"
    );

    return response ?? [];
};

export const getTemplatePositions = async (): Promise<ITemplatePosition[]> => {
    const response = await ApiModule.get<ITemplatePosition[]>(
        "/user-supply-point-position-mapping/positions "
    );

    return response ?? [];
};

type BodyType = {
    EffectiveDate: string;
    Mode: ResourceMode;
    PositionId: number;
    EnergySourceId: string;
};

export const setTemplatePosition = async (
    positions: number[],
    body: TemplateMap,
    year: string
) => {
    let result: BodyType[] = [];
    positions.forEach((pos) => {
        const parsed: BodyType[] = Object.values(body).reduce<any[]>(
            (acc: any[], cur: Record<string, IResource["Mode"]>) => {
                const parsed = Object.entries(cur).map(([key, value]) => {
                    const obj = {
                        EffectiveDate: year,
                        Mode: value,
                        PositionId: pos,
                        EnergySourceId: key,
                    };
                    return obj;
                });
                return acc.concat(parsed);
            },
            []
        );
        result = result.concat(parsed);
    });
    await ApiModule.post("/SupplyPointPositionEnergySource", result);
    const response = positions.reduce<TemplateResponse>((acc, cur: number) => {
        return { ...acc, [cur]: { body, EffectiveDate: year } };
    }, {});
    return response;
};

export const getCreatingFormConfig = async (): Promise<
    ICreatingFormResponse[]
> => {
    const response = await ApiModule.get<ICreatingFormResponse[]>(
        "/EnergySource"
    );
    return response ?? [];
};

export const getRates = async (params?: {
    [key: string]: string;
}): Promise<IGuideRate[]> => {
    const data = await ApiModule.post<
        Omit<IGuideRate, "key">[],
        Partial<IRatesParams>
    >("/RateTemplate/list", params);
    return (data || []).map((rate) => ({ ...rate, key: rate.Id }));
};

type GoodRate = {
    NewReportTableId: number;
    NewRateTemplateId: number;
};

type BadRate = {
    ExistRateTemplateId: number;
    ExistReportTableId: number;
};

const isGoodRate = (rate: GoodRate | BadRate | undefined): rate is GoodRate => {
    return !!rate && "NewReportTableId" in rate;
};

export const createRate = async (data: IRatesParams) => {
    const response = await ApiModule.post<GoodRate | BadRate, IRatesParams>(
        "/RateTemplate/generate",
        data
    );
    if (isGoodRate(response))
        return {
            id: response.NewReportTableId,
            tableId: response.NewReportTableId,
            success: true,
        };
    return {
        id: response?.ExistRateTemplateId ?? 0,
        tableId: response?.ExistReportTableId ?? 0,
        success: false,
    };
};
