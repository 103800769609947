import { RootState } from "@/app/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseInstanse, RestInstanse } from "../../app/axiosInstance";
import { getAuth } from "../../helpers/getAuth";
import { RequestState } from "../requestTypes";
import { callError } from "shared/helpers";
import { ApiModule } from "shared/ApiModule";

interface IInitialStateLogin {
    loginAndPass: {
        username: string;
        password: string;
    };
    currentUser: ICurrentUser | null;
    currentUserStatus: RequestState;
    availableUrls: string[];
}

export interface IFetchLoginAppBasic {
    login: string;
    password: string;
    onSuccess?: (data: any) => void;
    onError?: () => void;
}

export interface ICurrentUser {
    DisplayName: string;
    FirstName: string;
    Id: number;
    IsMaster: boolean;
    LastName: string;
    Login: string;
}

// Небольшая документация.
// Накаждый запрос нужна basic auth
// Поэтмоу пробрасываем поля логин пароль каждый раз, который будут храниться в localStorage
// Каждый раз запросы переадресовывают на html страницу, но мы это не ловим.
// Открывать приложение локально надо на 5500 порту, и в nginx писать на него разрешение, если вдруг ошибки вознакают в cors

export const fetchLoginAppBasic = createAsyncThunk<any, IFetchLoginAppBasic>(
    "loginAppBasic",
    async ({ login, password }) => {
        // await BaseInstanse.post(
        //     `/login.html`,
        //     { ...getAuth() },
        //     {
        //         ...getAuth(),
        //         auth: {
        //             username: login,
        //             password: password,
        //         },
        //     }
        // );
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.setItem("username", login);
        localStorage.setItem("password", password);
        const loginResponse = await ApiModule.get<ICurrentUser>(
            `/users/current`
        );
        let user = loginResponse ?? null;

        return {
            user,
            login,
            password,
        };

        // return data;
    }
);

export const fetchLogin = createAsyncThunk(
    "asyncGetUser",
    async (_, thunkApi) => {
        const response = await RestInstanse.get<ICurrentUser>(
            `/users/current`,
            getAuth()
        );
        const data: ICurrentUser = response.data;

        let availableUrls: string[] = [];

        const res = await ApiModule.get<string[]>(
            "/authorities/availablePoints"
        );
        availableUrls = res || [];

        return { data, availableUrls };
    }
);

const initialState: IInitialStateLogin = {
    loginAndPass: {
        username: "",
        password: "",
    },
    currentUser: null,
    currentUserStatus: "pending",
    availableUrls: [],
};

export const loginSlice = createSlice({
    name: "LoginSlice",
    initialState,
    reducers: {
        logout(state) {
            state.currentUser = null;
            state.currentUserStatus = "rejected";
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("role");
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            state.currentUser = action.payload.data;
            if (action.payload.data.IsMaster) {
                localStorage.setItem("role", "lineman");
            }
            state.availableUrls = action.payload.availableUrls;
            state.currentUserStatus = "fulfilled";
        });

        builder.addCase(fetchLogin.rejected, (state) => {
            state.currentUser = null;
            state.currentUserStatus = "rejected";
            state.availableUrls = [];
        });

        builder.addCase(fetchLogin.pending, (state) => {
            state.currentUserStatus = "pending";
        });

        builder.addCase(fetchLoginAppBasic.fulfilled, (state, action) => {
            state.loginAndPass = {
                username: action.payload.login,
                password: action.payload.password,
            };
            state.currentUserStatus = "fulfilled";

            state.currentUser = action.payload.user;
            if (action.payload.user.IsMaster) {
                localStorage.setItem("role", "lineman");
            }
            window.location.replace("/point-events-lineman");
        });
        builder.addCase(fetchLoginAppBasic.rejected, (state) => {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("role");
            callError("Неверный логин или пароль");
            state.currentUserStatus = "rejected";
        });
        // builder.addCase(fetchLoginAppBasic.pending, (state) => {
        //     state.currentUserStatus = "pending";
        // });
    },
});

export const { logout } = loginSlice.actions;

export const selectLoginAndPass = (state: RootState) =>
    state.Login.loginAndPass;

export const selectCurrentUser = (state: RootState) => state.Login.currentUser;

export const selectCurrentUserStatus = (state: RootState) =>
    state.Login.currentUserStatus;
