import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { loginSlice } from "../services/login/loginSlice";
import { pointEventsSlice } from "../services/pointEvents/pointEventsSlice";
import { cellMappingSlice } from "services/tableMappings/cellMappingSlice";
import { objectSlice } from "services/objects/objectsSlice";
import { reducer as excelReducer } from "entities/ExcelTable/slice";

export const store = configureStore({
    reducer: {
        Login: loginSlice.reducer,
        PointEvents: pointEventsSlice.reducer,
        CellMapping: cellMappingSlice.reducer,
        Objects: objectSlice.reducer,
        Excel: excelReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
