import { LeftOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { FC } from "react";

type Props = {
    title?: string;
    description?: string;
    closeShowed: () => void;
};

const ModalHeader: FC<Props> = React.memo((props) => {
    const { title, description, closeShowed } = props;

    return (
        <>
            <LeftOutlined
                onClick={closeShowed}
                style={{ fontSize: "30px", width: "30px" }}
            />

            {title && (
                <Typography.Title
                    level={2}
                    style={{
                        margin: 0,
                    }}
                >
                    {title}
                </Typography.Title>
            )}
            {description && (
                <Typography.Paragraph style={{ marginTop: 10, color: '#7d899a', fontSize:'16px' }}>
                    {description}
                </Typography.Paragraph>
            )}
        </>
    );
});

export default ModalHeader;