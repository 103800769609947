import classNames from "classnames";
import { FC, useMemo } from "react";
import styles from "./styles.module.css";
import { ResourceMode } from "entities/ISGuide/types";
import { useAppSelector } from "app/hooks";

type Props = {
    name: string;
    // value: Record<string, ResourceMode>;
    isError: boolean
};

const GuideTemplateResource: FC<Props> = (props) => {
    const { name, isError } = props;
    // const { resources } = useAppSelector((state) => state.Excel);

    // const isError = useMemo<boolean>(() => {
    //     const actualResources = resources.filter((el) => el.AxisName === name);
    //     const values = Object.values(value);
    //     return (
    //         values.length < actualResources.length ||
    //         values.some((v) => v === "NONE")
    //     );
    // }, [value, resources, name]);
    return (
        <div
            className={classNames(styles.resource, { [styles.error]: isError })}
        >
            {name}
        </div>
    );
};

export default GuideTemplateResource;
