import React, { FC, useState } from "react";
import RedButton from "../RedButton";

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
    onClick?: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => Promise<void>;
};

const CallbackButton: FC<Props> = (props) => {
    const { children, disabled, onClick } = props;

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const clickHandler = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsDisabled(true);
        onClick?.(e).finally(() => {
            setIsDisabled(false);
        });
    };

    return (
        <RedButton disabled={disabled || isDisabled} onClick={clickHandler}>
            {children}
        </RedButton>
    );
};

export default CallbackButton;
