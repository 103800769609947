import { useState } from "react";

export interface IPickedConfig {
    pickedCells: string[];
    operation: string;
}

const regExp = /^\([А-яA-z1234567890]{1}\) | \(Backspace\)$/

export const usePickedCells = (pickedCells?: string[]) => {
    const [data, setData] = useState<IPickedConfig>(() => {
        return { pickedCells: pickedCells || [], operation: "" };
    });


    const setPickedCell = (ids: string[]) => {
        setData((prev) => ({...prev, pickedCells: ids }));
    }

    const togglePickedCell = (cellId: string) => {
        setData((prev) => {
            const newPickedCells = [...prev.pickedCells];
            if (newPickedCells.includes(cellId)) {
                newPickedCells.splice(newPickedCells.indexOf(cellId), 1);
            } else {
                newPickedCells.push(cellId);
            }
            return { ...prev, pickedCells: newPickedCells };
        });
    };

    const setOperation = (value: string) => {
        setData((prev) => {
            const { operation } = prev;
            if (operation !== "Backspace" && value !== "Backspace") {
                return { ...prev, operation: `${prev.operation}${value}` };
            }
            return { ...prev, operation: value };
        });
    };

    return { pickedCells: data, togglePickedCell, setOperation, setPickedCell };
};
