import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useTableBodyContext, useTableContext } from "../context";
import classNames from "classnames";
import { useActions, useAppSelector } from "app/hooks";
import { ITableCell } from "entities/ExcelTable/types";
import { Button } from "antd";
import { useEffectSkipFirstRender } from "hooks";

type Props = {
    dataIndex: string;
    style?: React.CSSProperties;
    canHidden?: boolean;
    blocked?: boolean;
    value?: ITableCell;
};

const ExcelColumnItem: FC<Props> = (props) => {
    function setEditCell() {
        openStatus.current = true;
        if (inputRef.current !== null) {
            const input = inputRef.current as HTMLInputElement;
            input.focus();
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            const adaptedValue =
                value?.CellData?.CellType === "NUMERIC"
                    ? inputValue
                          .replaceAll(".", ",")
                          .replaceAll(/[А-яA-z\/\+\=\\]/g, "")
                          .replaceAll(/\D+$/g, "")
                    : inputValue;
            if (backup.current !== adaptedValue && value?.Id) {
                addEditing([String(value.Id), adaptedValue]);
                backup.current = adaptedValue;
                setInputValue(adaptedValue);
            }

            openStatus.current = false;
            increaseEditableCell();
        }
    }

    const { dataIndex, value, style, canHidden, blocked } = props;

    const valueInfo = useMemo(() => {
        return {
            stringValue: value ? String(value.CellData.Value ?? "") : "",
            notAllowedToEdit: blocked || value?.clickable,
        };
    }, [value, blocked]);

    const [inputValue, setInputValue] = useState<string>(valueInfo.stringValue);
    const openStatus = useRef(false);
    const backup = useRef<string>(valueInfo.stringValue);
    const ref = useRef(null);
    const [_, setTrigger] = useState(false);

    const { updateEditable, actualEditable, increaseEditableCell } =
        useTableBodyContext();

    const { hiddenCells, changeActiveCurrentCell } = useTableContext();

    const { promises, fontSize, activeIndex } = useAppSelector(
        (state) => state.Excel
    );
    const { addEditing } = useActions();

    useEffect(() => {
        if (actualEditable === dataIndex) {
            setEditCell();
        } else {
            openStatus.current = false;
            setInputValue(backup.current);
        }
        setTrigger((prev) => !prev);
    }, [actualEditable]);

    useEffect(() => {
        const cur =
            promises[String(activeIndex)]?.[dataIndex] ?? valueInfo.stringValue;
        backup.current = cur;
        setInputValue(cur);
    }, [dataIndex, valueInfo.stringValue]);

    const inputRef = useRef(null);

    const isEdited = useMemo<boolean>(() => {
        if (!value?.Id) return false;
        const current = promises[String(activeIndex?.rate)]?.[String(value.Id)];
        return !!current;
    }, [promises, value]);

    useEffectSkipFirstRender(() => {
        if (!isEdited) {
            backup.current = valueInfo.stringValue;
            setInputValue(valueInfo.stringValue);
        }
    }, isEdited);

    useEffect(() => {
        if (openStatus.current) {
            const input = inputRef.current;
            if (input !== null) {
                (input as HTMLInputElement).focus();
            }
        }
    }, [openStatus.current]);

    const isNone = useMemo<boolean>(() => {
        return !!canHidden && hiddenCells.some((el) => dataIndex.includes(el));
    }, [canHidden, hiddenCells]);

    return (
        <div
            ref={ref}
            style={style}
            data-id={dataIndex}
            className={classNames(styles["excel-column-item"], {
                [styles.active]: openStatus.current,
                [styles.edited]: isEdited && !openStatus.current,
                [styles.none]: isNone,
                [styles.uneditable]: blocked,
            })}
            onDoubleClick={() => {
                if (!valueInfo.notAllowedToEdit) {
                    setEditCell();
                    updateEditable(dataIndex);
                }
            }}
            onKeyUp={onEnterClick}
        >
            {value?.clickable ? (
                <Button onClick={() => changeActiveCurrentCell(value)}>
                    {valueInfo.stringValue}
                </Button>
            ) : (
                <>
                    {openStatus.current && (
                        <input
                            value={inputValue}
                            style={{ fontSize }}
                            ref={inputRef}
                            onChange={(e) => setInputValue(e.target.value)}
                            className={classNames(styles.input, {
                                [styles.hidden]: !openStatus.current,
                            })}
                        />
                    )}
                    <span
                        className={classNames(styles["excel-value"], {
                            [styles.none]: openStatus.current,
                        })}
                    >
                        {inputValue}
                    </span>
                </>
            )}
        </div>
    );
};

export default ExcelColumnItem;
