import { Select } from "antd";
import React, { useEffect, useMemo } from "react";
import {
    IEventPosition,
    IModifier,
    IPeriodModifier,
} from "services/pointEvents/pointEventsSlice";
import dayjs from "dayjs";
import moment from "moment";
import { RedLine } from "shared/components";

interface IPeriodWrapper {
    period: IPeriodModifier;
    setPeriods: (value: React.SetStateAction<IPeriodModifier[]>) => void;
    beginDate: string;
    currentPosition: IEventPosition[];
    i: number;
}

type ModifierDateKeys = {
    [K in keyof IModifier]: K extends `${infer Prefix}Date` ? K : never;
}[keyof IModifier];

type InputType =
    | keyof IModifier
    | "BeginDateHour"
    | "BeginDateMinute"
    | "EndDateHour"
    | "EndDateMinute"
    | "EndDateHourMinute";

function isDateField(key: InputType): key is ModifierDateKeys {
    return key.includes("Date");
}

const { Option } = Select;

export const changePeriod = (
    setPeriods: (value: React.SetStateAction<IPeriodModifier[]>) => void,
    beginDate: string,
    currentPosition: IEventPosition[],
    val: string,
    i: number,
    inputType: InputType,
    valueIndex?: number
) => {
    setPeriods((prev) => {
        const newPeriods = [...prev];
        let minutes,
            hours = "00";
        if (isDateField(inputType)) {
            const current =
                prev?.[i]?.[
                    inputType
                        .replace("Minute", "")
                        .replace("Hour", "") as ModifierDateKeys
                ];
            minutes = current.slice(14, 16) || "00";
            hours = current.slice(11, 13) || "00";
        }
        // const minutes = prev?.[i]?.[inputType.replace('Minute','').replace('Hour','') as keyof IModifier]?.slice(14,16) ? prev?.[i]?.[inputType.replace('Minute','').replace('Hour','') as keyof IModifier]?.slice(14,16) : '00'
        // const hours = prev?.[i]?.[inputType.replace('Minute','').replace('Hour','') as keyof IModifier]?.slice(11,13) ? prev?.[i]?.[inputType.replace('Minute','').replace('Hour','') as keyof IModifier]?.slice(11,13) : '00'
        const value =
            inputType === "EndDateHourMinute"
                ? beginDate?.split("T")?.[0]?.split(".")?.reverse()?.join("-") +
                  "T" +
                  val
                : inputType === "BeginDateHour" || inputType === "EndDateHour"
                ? beginDate?.split("T")?.[0]?.split(".")?.reverse()?.join("-") +
                  "T" +
                  val +
                  ":" +
                  minutes
                : inputType === "BeginDateMinute" ||
                  inputType === "EndDateMinute"
                ? beginDate?.split("T")?.[0]?.split(".")?.reverse()?.join("-") +
                  "T" +
                  hours +
                  ":" +
                  val
                : inputType === "Value" && valueIndex !== 1
                ? [
                      Math.abs(+val) * (valueIndex === 0 ? -1 : 1),
                      prev?.[i]?.Value?.[1],
                  ]
                : inputType === "Value" && valueIndex === 1
                ? [prev?.[i]?.Value?.[0], +val]
                : val;
        newPeriods[i] = {
            ...newPeriods[i],
            [inputType.replace("Minute", "").replace("Hour", "")]: value,
            Position: [
                currentPosition?.[0]?.Position,
                currentPosition?.[1]?.Position,
            ],
        };
        return [...newPeriods];
    });
};

const hours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
];
const minutes = ["00", "15", "30", "45"];

export const PeriodWrapper = ({
    period,
    setPeriods,
    beginDate,
    currentPosition,
    i,
}: IPeriodWrapper) => {
    const availableEndHoursAndMinutes = useMemo(() => {
        const [hour, currentMinutes] = period?.BeginDate?.split(
            "T"
        )?.[1]?.split(":") ?? ["00", "00"];
        const hourIndex = hours.indexOf(hour);
        const minutesIndex = minutes.indexOf(currentMinutes);
        const availableHours = hours.slice(
            minutesIndex === minutes.length - 1 && hourIndex != hours.length - 1
                ? hourIndex + 1
                : hourIndex
        );

        let availableMinutes: string[] = [];
        const endDateHourIndex = hours.indexOf(
            period?.EndDate?.split("T")?.[1]?.split(":")?.[0]
        );
        if (endDateHourIndex === -1 || endDateHourIndex > hourIndex) {
            availableMinutes = minutes;
        } else {
            availableMinutes = minutes.slice(
                minutesIndex === minutes.length - 1
                    ? minutesIndex
                    : minutesIndex + 1
            );
        }

        return [availableHours, availableMinutes];
    }, [period.BeginDate, period.EndDate]);

    useEffect(() => {
        const endDate = period.EndDate?.split("T")?.[1]?.split(":");

        if (!endDate) return;

        const [hour, currentMinutes] = endDate;

        let times = [hour, currentMinutes];

        availableEndHoursAndMinutes.forEach((el, index) => {
            if (!el.includes(times[index])) {
                times[index] = el[0];
            }
        });

        changePeriod(
            setPeriods,
            beginDate,
            currentPosition,
            times.join(":"),
            i,
            "EndDateHourMinute"
        );
    }, [availableEndHoursAndMinutes]);

    return (
        <div className="pair-wrapper">
            <div className="pair">
                с
                <Select
                    placeholder="Часы"
                    className="editable-select datetime"
                    value={period?.BeginDate?.split("T")?.[1]?.split(":")?.[0]}
                    onChange={(val: string) =>
                        changePeriod(
                            setPeriods,
                            beginDate,
                            currentPosition,
                            val,
                            i,
                            "BeginDateHour"
                        )
                    }
                >
                    {hours?.map((hour) => (
                        <Option key={hour} value={hour}>
                            {hour}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder="Минуты"
                    className="editable-select datetime"
                    value={period?.BeginDate?.split("T")?.[1]?.split(":")?.[1]}
                    onChange={(val: string) =>
                        changePeriod(
                            setPeriods,
                            beginDate,
                            currentPosition,
                            val,
                            i,
                            "BeginDateMinute"
                        )
                    }
                >
                    {minutes?.map((min) => (
                        <Option key={min} value={min}>
                            {min}
                        </Option>
                    ))}
                </Select>
            </div>
            <RedLine />
            <div className="pair">
                до
                <Select
                    placeholder="Часы"
                    notFoundContent="Не найдено"
                    className="editable-select datetime"
                    value={period?.EndDate?.split("T")?.[1]?.split(":")?.[0]}
                    onChange={(val: string) =>
                        changePeriod(
                            setPeriods,
                            beginDate,
                            currentPosition,
                            val,
                            i,
                            "EndDateHour"
                        )
                    }
                >
                    {availableEndHoursAndMinutes[0].map((hour) => (
                        <Option key={hour} value={hour}>
                            {hour}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder="Минуты"
                    notFoundContent="Ничего нет. И куда подевалось?"
                    className="editable-select datetime"
                    value={period?.EndDate?.split("T")?.[1]?.split(":")?.[1]}
                    onChange={(val: string) =>
                        changePeriod(
                            setPeriods,
                            beginDate,
                            currentPosition,
                            val,
                            i,
                            "EndDateMinute"
                        )
                    }
                >
                    {availableEndHoursAndMinutes[1].map((min) => (
                        <Option key={min} value={min}>
                            {min}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

export const changeTransitionPeriod = (
    setPeriods: (value: React.SetStateAction<IPeriodModifier[]>) => void,
    beginDate: string,
    currentPosition: IEventPosition[],
    val: string,
    i: number
) => {
    setPeriods((prev) => {
        const newPeriods = [...prev];
        const beginDateNew =
            beginDate?.split("T")?.[0]?.split(".")?.reverse()?.join("-") +
            "T" +
            val.split("-")?.[0] +
            ":00";
        const endDateNew =
            beginDate?.split("T")?.[0]?.split(".")?.reverse()?.join("-") +
            "T23:59";
        newPeriods[i] = {
            ...newPeriods[i],
            BeginDate: beginDateNew,
            EndDate: endDateNew,
            Position: currentPosition.map(el => el.Position),
            // Position: [
            //     currentPosition?.[0]?.Position,
            //     currentPosition?.[1]?.Position,
            // ],
        };
        return [...newPeriods];
    });
};

const intervals = [
    "00-01",
    "01-02",
    "02-03",
    "03-04",
    "04-05",
    "05-06",
    "06-07",
    "07-08",
    "08-09",
    "09-10",
    "10-11",
    "11-12",
    "12-13",
    "13-14",
    "14-15",
    "15-16",
    "16-17",
    "17-18",
    "18-19",
    "19-20",
    "20-21",
    "21-22",
    "22-23",
    "23-00",
];

export const PeriodTransitionWrapper = ({
    period,
    setPeriods,
    beginDate,
    currentPosition,
    i,
}: IPeriodWrapper) => {
    const intervalValue = intervals?.find(
        (interval) =>
            interval?.split("-")?.[0] ===
            period?.BeginDate?.split("T")?.[1]?.split(":")?.[0]
    );

    return (
        <div className="pair-wrapper">
            <div className="pair">
                с
                <Select
                    className="editable-select datetime"
                    style={{ width: "132px" }}
                    placeholder="Интервал"
                    value={intervalValue}
                    onChange={(value: string) =>
                        changeTransitionPeriod(
                            setPeriods,
                            beginDate,
                            currentPosition,
                            value,
                            i
                        )
                    }
                >
                    {intervals?.map((interval) => (
                        <Option key={interval} value={interval}>
                            {interval}
                        </Option>
                    ))}
                </Select>
            </div>
            <RedLine />
            <div className="pair">
                <div style={{ width: "100px" }}>до конца дня</div>
            </div>
        </div>
    );
};
