export const columns = [
  {
    title: "Ячейка",
    dataIndex: "Name",
    editable: true,
    required: true,
  },
  {
    title: "Произвольное название",
    dataIndex: "FriendlyName",
    editable: true,
    required: true
  },
  {
    title: "Парсер",
    editable: true,
    dataIndex: "Parser",
    // required: true
  },
  {
    title: "Тег 1",
    editable: true,
    dataIndex: "Tag",
    // required: true
  },
  {
    title: "Тег 2",
    editable: true,
    dataIndex: "Tag1",
    // required: true
  },
  {
    title: "Разрешение",
    dataIndex: "Granularity",
    editable: true,
    options: [{key: '15', value: 15}, {key: '30', value: 30}, {key: '60', value: 60}],
    required: true
  },
  {
    title: "Измеряемая?",
    dataIndex: "Measurable",
    editable: true,
    options: [
      { key: "Да", value: true },
      { key: "Нет", value: false },
    ],
    required: true,
    render(value: boolean) {
      return <span>{value ? "Да" : "Нет"}</span>;
    },
  },
  {
    title: "Тип",
    dataIndex: "Type",
    editable: true,
    options: [
      { key: "Входящий", value: "INPUT" },
      { key: "Исходящий", value: "OUTPUT" },
    ],
    render(value: string) {
      return <span>{value === "INPUT" ? "Входящий" : "Исходящий"}</span>;
    },
    required: true
  },
  {
    title: "Комментарии",
    dataIndex: "Comment",
    editable: true,
  },
  {
    title: "Действия",
    dataIndex: "actions",
    editable: true,
  },
];
