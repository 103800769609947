import React, { FC, PropsWithChildren, useId, } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { FilterOutlined } from "@ant-design/icons";


type Props = {
    backgroundColor?: string;
    className?: string;
    title: string;
};

const Accordion: FC<PropsWithChildren<Props>> = (props) => {
    const { className, children, title } = props;

    const id = useId();

    return (
        <div className={styles['accordion-container']}>
            <div className={classNames(styles["accordion-header"], className)}>
                <input id={id} type="checkbox" />
                <label htmlFor={id}><FilterOutlined /> {title}</label>
            </div>

            {children && (
                <div className={styles["accordion-main"]}>{children}</div>
            )}
        </ div>
    );
};

export default Accordion;
