import { useState } from "react";

export function useMap<T>(defaultValue?: T[]): [T[], (name: T) => void] {
    const [data, setData] = useState<T[]>(defaultValue ?? []);

    function toggleData(name: T) {
        const id = data.indexOf(name);
        if (id === -1) {
            setData([...data, name]);
            return;
        }
        setData([...data.slice(0, id), ...data.slice(id + 1)]);
    }

    return [data, toggleData];
}
