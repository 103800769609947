import { Modal, Space, Spin } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ICellActionType, ITableCell } from "entities/ExcelTable/types";
import { useTableContext } from "ExcelTable/context";
import { ApiModule } from "shared/ApiModule";
import { cellActionsDictionary } from "entities/ExcelTable/helpers/constants";
import { useAppSelector } from "app/hooks";

type Props = {
    actionCell: ITableCell | null;
};

const CellActionModal: FC<Props> = ({ actionCell }) => {
    const { changeActiveCurrentCell } = useTableContext();
    // const {
    //     standartStatus: { date },
    // } = useAppSelector((state) => state.Excel);

    function getCurrentModalTitle(): string {
        if (currentActionType === null) return "";
        const { title, needDate } = currentActionType;
        return needDate ? `${title} на` : title;
    }

    const cleanModal = () => {
        changeActiveCurrentCell();
    };

    const currentActionType = useMemo<ICellActionType | null>(() => {
        if (!actionCell?.onClick) {
            return null;
        }
        return cellActionsDictionary[actionCell.onClick];
    }, [actionCell]);
    const [data, setData] = useState<{ key: string; value: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (currentActionType) {
            setIsLoading(true);
            const { url } = currentActionType;
            ApiModule.get<{ key: string; value: string }[]>(url)
                .then((values) => {
                    setData(values || []);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [actionCell]);

    return (
        <Modal
            centered
            width={850}
            open={!!actionCell}
            onClose={cleanModal}
            onCancel={cleanModal}
            onOk={cleanModal}
            title={getCurrentModalTitle()}
            footer={(_, { OkBtn }) => (
                <>
                    <OkBtn />
                </>
            )}
        >
            <div style={{ height: 500 }}>
                {currentActionType &&
                    React.createElement(currentActionType.Component, {
                        values: data,
                        isLoading,
                    })}
            </div>
        </Modal>
    );
};

export default CellActionModal;
