import { createContext, useContext } from "react";

interface ITableEditContext {
    setAllPageEdit: (id: number, key?: string, value?: string) =>  void;
}

export const TableEditContext = createContext<ITableEditContext | undefined>(
    undefined
);

export const useTableEditContext = () => {
    const context = useContext(TableEditContext);
    if (!context) throw new Error("context error");
    return context;
};
