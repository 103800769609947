import { AddItemModal, EditableTable } from "../../components/Widgets";
import {
  updateCell,
  createCell,
  getCells,
  deleteCell,
} from "entities/MappingPositionsCell/api";
import { useFetch } from "hooks";
import { columns } from "entities/EditableTable/Constants";
import { useState } from "react";
import { AddButton } from "../../components/UI";
import styles from "./styles.module.scss";

const fieldsForCreate = columns.slice(0, -1);

export const PointcellMappingPage = () => {
  const { data, trigger } = useFetch(getCells, null);

  const [showed, setShowed] = useState(false);

  if (!data) return null;
  return (
    <>
      <div className="event-page-header">
        <h1>Ячейки по ОЛС</h1>
        <AddButton
          onClick={() => {
            setShowed((prev) => !prev);
          }}
        >
          Создать ячейку
        </AddButton>
      </div>

      <EditableTable
        data={data}
        refetch={trigger}
        columns={columns}
        actions={[
          {
            type: "edit",
            action: updateCell,
          },
          {
            type: "delete",
            action: deleteCell,
          },
        ]}
      />
      <AddItemModal
        title="Создание ячейки"
        showed={showed}
        fields={fieldsForCreate}
        closeShowed={() => setShowed(false)}
        onAccept={async (data: any) => {
          await createCell(data).then(trigger);
        }}
        description="Введите данные для создания ячейки и сохраните изменения"
      />
    </>
  );
};
