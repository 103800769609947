import { useMemo } from "react";

import { ITableColumn } from "entities/ExcelTable/types";
import { useTableContext } from "ExcelTable/context";

export const withSortingClass = <P extends { column: ITableColumn }>(
    Component: React.ComponentType<P & { sortingClass: string }>
) => {
    return (props: P) => {
        const { column } = props;
        const { sorted } = useTableContext();

        const currentSortingClass = useMemo<string>(() => {
            if (!sorted.field) return "";
            if (
                sorted.field !== `Cell-${column.Id}` ||
                sorted.mode === "default"
            )
                return "";
            return sorted.mode;
        }, [sorted]);

        return (
            <>
                <Component
                    {...(props as P)}
                    sortingClass={currentSortingClass}
                />
            </>
        );
    };
};
