import { FC, useState, memo } from "react";
import { TablePrimaryButton } from "entities/ExcelTable/components";
import ArchiveContent from "entities/ExcelTable/components/Archive/ArchiveContent";
import { Modal } from "shared/components";
import { getReportItems } from "entities/ExcelTable/helpers/archive.constants";
import { FileText } from "lucide-react";
import { TableTabItem, TabsParams } from "entities/ExcelTable/types";
import { availableYears } from "shared/constants/common";

type Props = {
    type: TabsParams;
    // filters: { [key: string]: string | number };
    createTab: (item: TableTabItem) => void;
    tabs: TableTabItem[];
};

const ReportArchive: FC<Props> = memo((props) => {
    const { type, createTab, tabs } = props;
    const isReport = type === "balance";

    const [open, setOpen] = useState<boolean>(false);

    const onCloseModal = () => {
        setOpen(false);
    };

    const textConfig = {
        title: `Архив ${isReport ? "отчетов" : "нормативов"}`,
        description: `Тут находятся все ${
            isReport ? "отчеты" : "нормативы"
        }, которые были закрыты во время работы`,
    };

    return (
        <>
            <TablePrimaryButton
                onClick={() => setOpen(true)}
                icon={<FileText fontSize={18} color="#191818" />}
            >
                Архив {isReport ? "отчетов" : "нормативов"}
            </TablePrimaryButton>
            <Modal
                closedOnAway={false}
                showed={open}
                closeShowed={onCloseModal}
                title={textConfig.title}
                description={textConfig.description}
            >
                <ArchiveContent
                    type={type}
                    tabs={tabs}
                    isShowed={open}
                    closeShowed={onCloseModal}
                    createTab={createTab}
                    staticItems={
                        isReport
                            ? getReportItems
                            : [
                                  {
                                      name: "Mode",
                                      items: [
                                          {
                                              key: "Постоянные",
                                              value: "CONST",
                                          },
                                          {
                                              key: "Переменная",
                                              value: "VARIABLE",
                                          },
                                      ],
                                  },
                                  {
                                      name: "Period",
                                      itemsInLine: 4,
                                      startIndex: 3,
                                      items: availableYears,
                                  },
                              ]
                    }
                />
            </Modal>
        </>
    );
});

export default ReportArchive;
