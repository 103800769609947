import { FC } from "react";
import { FlexInLine } from "shared/components";
import { Flex, Select } from "antd";
import { years } from "../../utils";
import { PeriodFilter } from "entities/ExcelTable/types";

type Props = {
    filters: PeriodFilter;
    updateFilter: (
        key: keyof PeriodFilter,
        value: PeriodFilter[keyof PeriodFilter]
    ) => void;
};

const YearSettings: FC<Props> = (props) => {
    const { filters, updateFilter } = props;

    return (
        <FlexInLine gap={15}>
            <Flex vertical gap={5}>
                <label htmlFor="year">Год:</label>
                <Select
                    id="year"
                    options={years.map((el) => ({
                        value: el.value,
                        label: <span>{el.name}</span>,
                    }))}
                    value={filters.year}
                    onChange={(val) => updateFilter("year", val)}
                />
            </Flex>
        </FlexInLine>
    );
};

export default YearSettings;
