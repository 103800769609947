import React, { FC } from "react";
import {
    IEventPosition,
    IPeriodModifier,
} from "services/pointEvents/pointEventsSlice";
import { changePeriod, PeriodTransitionWrapper } from "../../PeriodWrapper";
import { ICellMapping } from "entities/MappingPositionsCell/Types/mapPositions";
import PeriodHeader from "../PeriodHeader";
import { Input } from "antd";
import { RedLine } from "shared/components";

type Props = {
    periods: IPeriodModifier[];
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
    cellMappings: ICellMapping[][];
    updatePeriod: (id: number, period: IPeriodModifier) => void;
    currentPosition: IEventPosition[];
};

const TransitionPeriods: FC<Props> = (props) => {
    const {
        periods,
        beginDate,
        setPeriods,
        currentPosition,
        updatePeriod,
        cellMappings,
    } = props;

    return (
        <div>
            {periods?.map((period, i) => (
                <div className="period-container" key={"period" + i}>
                    {cellMappings && (
                        <PeriodHeader
                            cellMappings={cellMappings}
                            id={i}
                            updatePeriod={updatePeriod}
                            period={period}
                        />
                    )}

                    <PeriodTransitionWrapper
                        period={period}
                        setPeriods={setPeriods}
                        beginDate={beginDate}
                        currentPosition={currentPosition}
                        i={i}
                    />
                    <div className="pair-wrapper">
                        <div className="pair">
                            -
                            <Input
                                value={Math.abs(period?.Value?.[0])}
                                placeholder="Модификатор"
                                onChange={(e) =>
                                    changePeriod(
                                        setPeriods,
                                        beginDate,
                                        currentPosition,
                                        e.target.value,
                                        i,
                                        "Value",
                                        0
                                    )
                                }
                            />
                        </div>
                        <RedLine />
                        <div className="pair">
                            +
                            <Input
                                type="text"
                                value={period?.Value?.[1]}
                                placeholder="Модификатор"
                                onChange={(e) =>
                                    changePeriod(
                                        setPeriods,
                                        beginDate,
                                    currentPosition,
                                        e.target.value,
                                        i,
                                        "Value",
                                        1
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TransitionPeriods;
