import { DirectionType } from "shared/types/skeleton";
import styles from "./styles.module.css";
import classNames from "classnames";

interface Props {
    count?: number;
    direction?: DirectionType;
}

const Skeleton = ({ count = 1, direction = "column" }: Props) => {
    return (
        <>
            {count > 1 ? (
                <ul
                    className={classNames({
                        [styles.columnList]: direction === "column",
                        [styles.rowList]: direction !== "column",
                    })}
                >
                    {Array.from({ length: count }, (_, index) => index).map(
                        (_, index) => (
                            <li key={index} className={styles.item}></li>
                        )
                    )}
                </ul>
            ) : (
                <li className={styles.item}></li>
            )}
        </>
    );
};

export default Skeleton;
