import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "@/app/store";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import simpleEncryptDecrypt from "pages/PointEventsPage/simpleEncryptDecrypt";
import {
    fetchLogin,
    ICurrentUser,
    selectCurrentUser,
    selectCurrentUserStatus,
} from "services/login/loginSlice";
import { routes } from "shared/constants/routes";
import { secretKey } from "shared/constants/common";
import { RealRoute } from "shared/types";

function generateRoutes(user: ICurrentUser | null) {
    if (user === null) return { values: routes.slice(0, 2), path: "/" };
    return { values: routes, path: "/point-events-lineman" };
}

export const useGetCurrentUser = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const status = useAppSelector((state: RootState) =>
        selectCurrentUserStatus(state)
    );
    const currentUser = useAppSelector((state: RootState) =>
        selectCurrentUser(state)
    );

    const { availableUrls } = useAppSelector((state) => state.Login);
    const realRoutes = useMemo<RealRoute>(() => {
        const { values, path: defaultPath } = generateRoutes(currentUser);
        const actualRoutes = values.filter(
            (route) =>
                !route.permission || availableUrls.includes(route.permission)
        );
        return { actualRoutes, defaultPath };
    }, [availableUrls, currentUser]);

    useEffect(() => {
        const up = searchParams.get("up");
        if (up) {
            const decryptedText = simpleEncryptDecrypt(
                searchParams?.get("up") as string,
                secretKey
            );
            const [authorize, cookies] = decryptedText.split("Infinity");
            const [username, password] = authorize.split("&");
            localStorage.setItem("password", password);
            localStorage.setItem("username", username);
            cookies.split("; ").forEach((cookie) => {
                const [key, value] = cookie.split("=");
                document.cookie = `${key}=${value}; max-age=3600000; path=/`;
            });
            setSearchParams({});
        }
        dispatch(fetchLogin());
    }, []);

    return {
        currentUser,
        status,
        realRoutes,
    };
};
