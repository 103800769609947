import { IRadioFilterItem } from "shared/types/is-energy";
import { CellHistoryTable } from "../components";
import {
    ActionTypes,
    ICellActionType,
    ResponseTable,
    TableVariants,
} from "../types";

export const menuConfig: { name: string; value: TableVariants }[] = [
    { name: "Эл.энергия", value: "Electric" },
    {
        name: "Сжатый воздух",
        value: "Compressed",
    },
    { name: "Тепло", value: "Heating" },
    { name: "Топливо", value: "Fuel" },
];

export const standarts: ResponseTable = {
    Id: 1,
    Size: 16,
    Columns: [
        {
            Id: 1,
            Name: "Наименование",
        },
        {
            Id: 4,
            Name: "Сырье                   [тонн]",
            Children: [
                {
                    Id: 2,
                    Name: "План",
                },
                {
                    Id: 3,
                    Name: "Факт",
                },
            ],
        },
        {
            Id: 7,
            Name: "Уд.  норма   [кВт-ч/т]",
            Children: [
                {
                    Id: 6,
                    Name: "Факт",
                },
                {
                    Id: 5,
                    Name: "План",
                },
            ],
        },
        {
            Id: 10,
            Name: "РАСХОД                               [кВт-ч]",
            Children: [
                {
                    Id: 8,
                    Name: "План",
                },
                {
                    Id: 9,
                    Name: "Факт",
                },
            ],
        },
        {
            Id: 13,
            Name: "ОТКЛОНЕНИЕ",
            Children: [
                {
                    Id: 11,
                    Name: "кВт-ч",
                },
                {
                    Id: 12,
                    Name: "%",
                },
            ],
        },
    ],
    Rows: [],
    EffectiveDate: "2024-10-16T07:50:40.627",
};

export const cellActionsDictionary: Record<ActionTypes, ICellActionType> = {
    history: {
        url: "/",
        Component: CellHistoryTable,
        title: "Показатели нормы",
        needDate: true,
    },
};


