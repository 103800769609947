import { lazy } from "react";
import { IRoute } from "../types/routes";
import LoginPage from "pages/LoginPage/LoginPage";
import { PointcellMappingPage } from "pages/PointCellMapping/PointCellMappingPage";
import PointEventsPage from "pages/PointEventsPage/PointEventsPage";
import ISTablePage from "pages/ExcelTable/ISTablePage";
import { ISEnergyMainContainer } from "shared/components";

const TablePage = lazy(() => import("../../pages/ExcelTable/ISMainPage"));
const CmsPage = lazy(() => import("../../pages/CMSPage"));
const CellPage = lazy(() => import("../../pages/CellPage"));
const MappingCellPage = lazy(() => import("../../pages/MappingCellPage"));
const TypesPage = lazy(() => import("../../pages/TypesPage"));
const MappingUserObjectPage = lazy(
    () => import("../..//pages/MappingUserObject")
);

export const routes: IRoute[] = [
    { path: "/", Component: <LoginPage />, name: "Авторизация" },
    {
        path: "/login",
        Component: <LoginPage />,
        name: "Авторизация",
    },
    {
        path: "/point-events-lineman",
        Component: <PointEventsPage />,
        name: "Создание событий",
        permission: "event_creation",
        module: 'events',
    },
    {
        path: "/supply-point-cell-mapping",
        Component: <PointcellMappingPage />,
        name: "Ячейки по ОЛС",
        permission: "cell",
        module: 'events',
    },
    {
        path: "/mapping-cells",
        Component: <MappingCellPage />,
        name: "Мэппинг ячеек",
        permission: "cell_mapping",
        module: 'events',
    },
    {
        path: "/positions",
        Component: <CellPage />,
        name: "Позиции (агрегаты)",
        permission: "position",
        module: 'events',
    },
    {
        path: "/types-creating",
        Component: <TypesPage />,
        name: "Типы событий",
        permission: "position_type",
        module: 'events',
    },
    {
        path: "/mapping-object-users",
        Component: <MappingUserObjectPage />,
        name: "Мэппинг доступов к объектам",
        permission: "user_to_supply_point",
        module: 'events',
    },
    {
        path: "/point-events-acceptor",
        Component: <PointEventsPage />,
        name: "Одобрение событий",
        permission: "event_acception",
        module: 'events',
    },
    {
        path: "/is-energy",
        Component: <TablePage />,
        MotherComponent: <ISEnergyMainContainer />,
        permission: "is_energy",
        module: 'is_energy',
        name: "ИС-Энергия",
        children: [
            {
                path: ":type",
                Component: <TablePage />,
                name: "ИС-Энергия",
            },
            {
                path: ":type/:id",
                Component: <ISTablePage />,
                name: "Таблица",
            },
        ],
    },
    {
        path: "/admin-side",
        Component: <CmsPage />,
        name: "Административная панель",
        module: 'events',
        permission: "admin_panel",
    },
];
