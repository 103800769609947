import { FC } from "react";
import { ICrumb } from "../../types";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import classNames from "classnames";
import { ChevronRight } from "lucide-react";

type Props = {
    crumb: ICrumb;
};

const Crumb: FC<Props> = ({ crumb }) => {
    const { label, isActive, url } = crumb;

    if (isActive)
        return (
            <div className={classNames(styles.link, styles.active)}>
                {label}
            </div>
        );
    return (
        <Link to={url} className={styles.link}>
            {label} <ChevronRight color="#BDBDBD" fontSize={18} />
        </Link>
    );
};

export default Crumb;
