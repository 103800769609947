import React, { forwardRef, useMemo } from "react";
import styles from "./style.module.css";
import ExcelHeaderCell from "./ExcelHeaderCell";
import ExcelHeaderNameCell from "./ExcelHeaderNameCell";
import { ITableColumn } from "entities/ExcelTable/types";
import ExcelHeaderSubCell from "./ExcelHeaderSubCell";
import { useAppSelector } from "app/hooks";

type Props = {
    columns: ITableColumn[];
    setAllClose: React.Dispatch<React.SetStateAction<boolean>>;
    onOpenRows: () => void;
};

const ExcelHeader = forwardRef<HTMLTableSectionElement, Props>((props, ref) => {
    const { columns, ...rest } = props;

    const {
        showHiddenValues,
        colors: { header: headerColor },
    } = useAppSelector((state) => state.Excel);

    const subColumns = useMemo<ITableColumn[]>(() => {
        return columns.reduce<ITableColumn[]>(
            (acc: ITableColumn[], cur: ITableColumn) => {
                if (!cur.Children?.length) return acc;
                const filteredChildren = cur.Children.filter(
                    ({ IsHidden }) => !IsHidden || showHiddenValues
                );
                return [...acc, ...filteredChildren];
            },
            []
        );
    }, [columns, showHiddenValues]);

    return (
        <thead ref={ref} className={styles["excel-head"]}>
            <tr
                className={styles["excel-header"]}
                style={{ backgroundColor: headerColor }}
            >
                <ExcelHeaderNameCell
                    column={columns[0]}
                    expanded={!!subColumns.length}
                    {...rest}
                />
                {columns.slice(1).map((el, index) => (
                    <ExcelHeaderCell
                        key={el.Id}
                        ariaColindex={index + 1}
                        column={el}
                    />
                ))}
            </tr>
            {!!subColumns.length && (
                <tr
                    className={styles["excel-header"]}
                    style={{ backgroundColor: headerColor }}
                >
                    {subColumns.map((el) => (
                        <ExcelHeaderSubCell
                            key={el.Id}
                            isHidden={false}
                            column={el}
                        />
                    ))}
                </tr>
            )}
        </thead>
    );
});

export default ExcelHeader;
