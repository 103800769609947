import { Flex } from "antd";
import { FC } from "react";
import RadioTabs from "shared/components/RadioTabs";
import { IRadioFilterItem } from "shared/types/is-energy";

type Props = {
    items: IRadioFilterItem[];
    value: { [key: string]: string };
    changeValue: (key: string, val: string) => void;
};

const ArchiveFilters: FC<Props> = (props) => {
    const { items, value, changeValue } = props;
    
    return (
        <Flex vertical gap={10}>
            {items
                .filter((el) => el.items.length > 0)
                .map((el) => (
                    <RadioTabs
                        key={el.name}
                        value={value[el.name]}
                        startIndex={el.startIndex}
                        itemsinLine={el.itemsInLine}
                        values={el.items}
                        changeString={(val: string) =>
                            changeValue(el.name, val)
                        }
                    />
                ))}
        </Flex>
    );
};

export default ArchiveFilters;
