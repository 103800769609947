import React, { FC, useCallback, useState } from "react";
import { IActionItem } from "entities/EditableTable/Types/actionCell";
import EditableActionButton from "../EditableActionButton";
import { Space } from "antd";
import { DataSource } from "entities/EditableTable/Types";

type Props = {
    actions: IActionItem[];
};

const EditableActionCell: FC<Props> = (props) => {
    const { actions } = props;

    const [disabled, setDisabled] = useState<boolean>(false);
    const updateDisable = (value: boolean) => {
        setDisabled(value);
    };

    return (
        <td>
            <Space size={8} align="center">
                {actions.map((el) => (
                    <EditableActionButton
                        item={el}
                        key={el.type}
                        disabled={disabled}
                        updateDisable={updateDisable}
                    />
                ))}
            </Space>
        </td>
    );
};

export default EditableActionCell;
