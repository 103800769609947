import { useEffect, useState } from "react";
import { getCellMappings } from "entities/MappingPositionsCell/api/mapTable";
import { ICellMapping } from "entities/MappingPositionsCell/Types/mapPositions";
import { EventPeriodType } from "entities/Types/types";
import { useEffectSkipFirstRender } from "hooks";
import {
    IEventPosition,
    IPeriodModifier,
    IPointEvent,
} from "services/pointEvents/pointEventsSlice";

interface IArguments {
    currentPosition: IEventPosition[];
    currentEvent?: IPointEvent;
    mainEventType: EventPeriodType;
}

function numbersHaveDefaultOrCurrent(
    mappings: ICellMapping[],
    currentId: number,
    pickedIds: number[]
) {
    let result: ICellMapping | undefined;

    result = mappings.find(
        (mapping) => mapping.Id === currentId && !pickedIds.includes(mapping.Id)
    );
    if (result === undefined) {
        result = mappings.find(
            (mapping) => mapping.IsDefault && !pickedIds.includes(mapping.Id)
        );
    }
    return result;
}

export const usePeriods = (params: IArguments) => {
    const { currentPosition, currentEvent, mainEventType } = params;

    const isTransition = mainEventType === "transition";
    const isReserveTest = mainEventType === "reverse";

    function getCellMappingValue(): {
        mappingIds: number | number[];
        modificators: number[];
    } {
        let value;
        let modificators: number[] = [];

        if (isTransition) {
            value = [];
            actualCellMappings.forEach((mapping) => {
                const defaultElem = mapping.find((el) => el.IsDefault);
                value.push(defaultElem?.Id ?? 0);
                modificators.push(defaultElem?.InstCapacity ?? 0);
            });
        } else {
            const firstDefault = actualCellMappings[0].find(
                (el) => el.IsDefault
            );
            value = firstDefault?.Id ?? 0;
            modificators = [firstDefault?.InstCapacity ?? 0];
        }
        return { mappingIds: value, modificators };
    }

    async function fetchMappings() {
        const promises = currentPosition.map((el) =>
            getCellMappings({
                targetSupplyPointPositionId: el.SupplyPointMappingId,
            })
        );
        const result: ICellMapping[][] = await Promise.all(promises).then(
            (res) => res
        );
        setActualCellMappings(result);
        return result;
    }

    const [actualCellMappings, setActualCellMappings] = useState<
        ICellMapping[][]
    >([[], []]);

    useEffect(() => {
        if (currentPosition.length) fetchMappings();
    }, []);

    useEffectSkipFirstRender(
        () => {
            const updateMappings = async () => {
                if (!currentPosition.length) return;
                const actual = await fetchMappings();
                setPeriods((prev) => {
                    return prev.map((el, id) => {
                        let values = [0, 0];
                        let newMappinsCellId: number | number[] = 0;
                        if (!isTransition) {
                            const currentElemInList = actual[0].find(
                                (item) =>
                                    item.Id ===
                                    (Array.isArray(el.CellMappingId)
                                        ? el.CellMappingId[0]
                                        : el.CellMappingId)
                            );

                            const defaultElement = actual[0].find(
                                (el) => el.IsDefault
                            );
                            newMappinsCellId =
                                currentElemInList?.Id ??
                                defaultElement?.Id ??
                                0;
                            values[0] =
                                currentElemInList?.InstCapacity ??
                                defaultElement?.InstCapacity ??
                                0;
                        } else {
                            const pickedMappings: number[] = [];
                            const actualTransitionMappings = Array.isArray(
                                el.CellMappingId
                            )
                                ? el.CellMappingId
                                : [el.CellMappingId as number, 0];

                            newMappinsCellId = actualTransitionMappings.reduce<
                                number[]
                            >((acc, cur, id) => {
                                const needableElem =
                                    numbersHaveDefaultOrCurrent(
                                        actual[id],
                                        cur,
                                        pickedMappings
                                    );
                                if (needableElem) {
                                    pickedMappings.push(needableElem.Id);
                                }
                                values[id] = needableElem?.InstCapacity ?? 0;
                                acc.push(needableElem?.Id ?? 0);
                                return acc;
                            }, []);
                        }

                        return {
                            ...el,
                            Position: isReserveTest
                                ? el.Position
                                : Array.from(
                                      { length: 2 },
                                      (_, k) =>
                                          currentPosition[k]?.Position ?? ""
                                  ),
                            Value: values,
                            CellMappingId: newMappinsCellId,
                        };
                    });
                });
            };
            updateMappings();
        },
        currentPosition,
        mainEventType
    );

    function generatePeriod(): IPeriodModifier {
        const { mappingIds, modificators } = getCellMappingValue();
        return {
            BeginDate: "",
            EndDate: "",
            Value: modificators,
            Position: isReserveTest
                ? []
                : currentPosition.map((el) => el.Position),
            CellMappingId: mappingIds,
        };
    }

    function getPeriodsFromEvent(): IPeriodModifier[] {
        if (!currentEvent) return [];
        const event = currentEvent;
        if (isTransition) {
            let index = 0;
            const data = event.ModifierData;
            const result: IPeriodModifier[] = [];
            while (index < data.length) {
                const [from, to] = [data[index], data[index + 1]];
                result.push({
                    BeginDate: from.BeginDate,
                    EndDate: from.EndDate,
                    Value: [Math.abs(from.Value), to.Value],
                    Position: [from.Position ?? "", to.Position ?? ""],
                    CellMappingId: from.CellMappingId
                        ? [
                              from.CellMappingId as number,
                              to.CellMappingId as number,
                          ]
                        : 0,
                });
                index += 2;
            }

            return result;
        }
        return event.ModifierData.map((el) => {
            return {
                BeginDate: el.BeginDate,
                EndDate: el.EndDate,
                Value: [el.Value, 1],
                Position: [el.Position ?? "", ""],
                CellMappingId: el.CellMappingId ?? 0,
            };
        });
    }

    function addPeriod() {
        const newPeriods = [...periods, generatePeriod()];
        setPeriods(newPeriods);
    }

    const [periods, setPeriods] = useState<IPeriodModifier[]>(() => {
        return currentEvent ? getPeriodsFromEvent() : [generatePeriod()];
    });

    return {
        addPeriod,
        periods,
        setPeriods,
        cellMappingsList: actualCellMappings,
    };
};
