import { useAppSelector } from "app/hooks";
import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

type Props = {
    name: string;
    permission: string | null;
};

const ProtectedRoute: FC<PropsWithChildren<Props>> = (props) => {
    const { children, name, permission } = props;

    const {availableUrls} = useAppSelector(state => state.Login);

    const blockNavigate = !(permission === null || availableUrls.includes(permission))

    if (!blockNavigate) {
        document.title = name;
    }

    if (blockNavigate) {
        return <Navigate to="/is-energy" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
