import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { MoveLeft, MoveRight } from "lucide-react";

type Props = {
    value: string;
    values: { key: string; value: string }[];
    changeString: (value: string) => void;
    itemsinLine?: number;
    startIndex?: number;
};

const RadioTabs: FC<Props> = React.memo((props) => {
    const { value, itemsinLine, values, changeString, startIndex } = props;

    const ref = useRef(null);

    const checkScrollStatus = (item: HTMLElement) => {
        if (item.scrollLeft + item.clientWidth >= item.scrollWidth) {
            setScrollStatus("right");
        } else if (item.scrollLeft <= 0) {
            setScrollStatus("left");
        } else {
            setScrollStatus("none");
        }
    };

    function scrollLeft() {
        if (!ref.current) return;
        const item = ref.current as HTMLElement;
        item.scrollLeft -= (item.clientWidth * sliderConfig.percentage) / 100;
        checkScrollStatus(item);
    }

    function scrollRight(cof?: number) {
        if (!ref.current) return;
        const item = ref.current as HTMLElement;
        item.scrollLeft +=
            (item.clientWidth * (sliderConfig.percentage * (cof ?? 1))) / 100;
        checkScrollStatus(item);
    }

    const sliderConfig = useMemo(() => {
        const hasSlider =
            itemsinLine === undefined ? false : values.length > itemsinLine;
        const percentage = 100 / (itemsinLine ?? values.length);
        return { hasSlider, percentage };
    }, [values, itemsinLine]);

    useEffect(() => {
        startIndex && scrollRight(startIndex);
    }, [startIndex]);

    const [scrollStatus, setScrollStatus] = useState<"right" | "left" | "none">(
        "left"
    );

    return (
        <div
            className={classNames(styles.tabs, {
                slider: sliderConfig.hasSlider,
            })}
        >
            {sliderConfig.hasSlider && (
                <button
                    className={classNames(styles["tab__left"], {
                        [styles.deadlock]: scrollStatus === "left",
                    })}
                    onClick={scrollLeft}
                >
                    <MoveLeft fontSize={18} color="#898989" />
                </button>
            )}
            <div ref={ref} className={styles["tab__list"]}>
                {values.map((item) => (
                    <RadioTab
                        value={value}
                        key={item.value}
                        percentWidth={sliderConfig.percentage}
                        item={item}
                        onChange={changeString}
                    />
                ))}
            </div>

            {sliderConfig.hasSlider && (
                <button
                    className={classNames(styles["tab__right"], {
                        [styles.deadlock]: scrollStatus === "right",
                    })}
                    onClick={() => scrollRight()}
                >
                    <MoveRight fontSize={18} color="#898989" />
                </button>
            )}
        </div>
    );
});

export default RadioTabs;

type RadioTabProps = {
    value: string;
    item: { key: string; value: string };
    onChange: (value: string) => void;
    percentWidth: number;
};
const RadioTab: FC<RadioTabProps> = (props) => {
    const { value, item, onChange, percentWidth } = props;

    const checked = value === item.value;

    return (
        <label
            className={classNames(styles["tab__item"], {
                [styles.checked]: checked,
            })}
            style={{ flex: `0 0 ${percentWidth}%` }}
        >
            <input
                type="radio"
                onChange={() => onChange(item.value)}
                checked={checked}
                className={styles["tab__radio"]}
                value={item.value}
            />{" "}
            <div className={styles["tab__label"]}>{item.key}</div>
        </label>
    );
};
