import React, { FC, useMemo } from "react";
import styles from "./style.module.css";
import { useTableContext } from "../context";
import classNames from "classnames";
import { ITableColumn } from "entities/ExcelTable/types";
import { withSortingClass } from "hocs/withSortingClass";
import { useAppSelector } from "app/hooks";

type Props = {
    column: ITableColumn;
    ariaColindex?: number;
};

const dataIndexRegExp = /^(row-[0-9.]+):([a-zA-z]+)\..+$/g;

const ExcelHeaderCell: FC<Props & { sortingClass: string }> = ({
    column,
    ariaColindex,
    sortingClass,
}) => {
    const { Name: name, Children: children } = column;
    const uniqName = useMemo(() => String(column.Id) + column.Name, [column]);

    const { currentColumn, changeColumnsPositions, toggleSorting } =
        useTableContext();

    const dragStartHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        const cell = e.target as HTMLTableCellElement;
        if (cell.dataset.column) currentColumn.current = cell.dataset.column;
    };

    const dragEndHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "1";
    };

    const dragOverHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "0.5";
    };

    const dropHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "1";
        changeColumnsPositions(cell);
        currentColumn.current = "";
    };

    const { showHiddenValues } = useAppSelector((state) => state.Excel);

    const colSpan = useMemo<number>(() => {
        if (!children) return 1;
        if (showHiddenValues) return children.length;
        return children.filter((el) => !el.IsHidden).length;
    }, [children, showHiddenValues]);

    const hasNotSubcolumns = !children?.length;

    const partsName = useMemo(() => {
        return name.split(`\n`);
    }, [name]);
    return (
        <th
            draggable
            onDragStart={dragStartHandler}
            onDragLeave={dragEndHandler}
            onDragEnd={dragEndHandler}
            onDragOver={dragOverHandler}
            onDrop={dropHandler}
            data-column={uniqName}
            aria-colindex={ariaColindex}
            className={styles["excel-header__element"]}
            colSpan={colSpan}
        >
            <div
                onClick={() => {
                    if (hasNotSubcolumns) {
                        toggleSorting(`Cell-${column.Id}`);
                    }
                }}
                className={classNames(
                    styles["excel-header__cell"],
                    styles[sortingClass],
                    {
                        [styles.alone]: hasNotSubcolumns,
                    }
                )}
            >
                {partsName.map((part, id) => (
                    <span key={id}>{part}</span>
                ))}
            </div>
        </th>
    );
};

export default withSortingClass(ExcelHeaderCell);
