import React, { FC, useMemo, useState } from "react";
import { Spin, Table, TablePaginationConfig } from "antd";
import {
    EditableColumnTypes,
    ColumnTypes,
    Data,
    DataSource,
} from "entities/EditableTable/Types";
import { EditableCell } from "entities/EditableTable/UI";
import { IActionItem } from "entities/EditableTable/Types/actionCell";
import { useUpdateArray } from "hooks";
import styles from "./style.module.css";
import { TableEditContext } from "./context";

type Props = {
    data?: Data[] | null;
    columns: EditableColumnTypes;
    actions?: IActionItem[];
    refetch?: () => Promise<void>; // refetch - слегка не однозначная сущность, вызывается после того, как произойдет мутация (редактирование, удаление), чтобы получить актуальные данные
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const EditableTable: FC<Props> = (props: Props) => {
    const { data, columns, actions, refetch } = props;
    const dataSource = useMemo<DataSource[]>(() => {
        if (!data) return [];
        return data.map((el) => ({ ...el, key: el.Id }));
    }, [data]);

    const [editableDataItems, editableDataItemsActions] =
        useUpdateArray<DataSource>();

    const [pageNumber, setPageNumber] = useState(1);

    function triggerPageEdit(id: number, key?: any, value?: any) {
        editableDataItemsActions.toggle(
            dataSource
                .slice(
                    (pageNumber - 1) * paginationOptions.pageSize,
                    pageNumber * paginationOptions.pageSize
                )
                .filter((el) => el.key !== id),
            key,
            value
        );
    }

    const paginationOptions = useMemo(() => {
        return {
            pageSize: 10,
            defaultPageSize: 10,
            hideOnSinglePage: true,
            onChange: (val: number) => {
                setPageNumber(val);
                editableDataItemsActions.reset();
            },
            showSizeChanger: false,
        };
    }, [editableDataItemsActions, setPageNumber]);

    const columns2 = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataSource) => {
                return {
                    record,
                    editable: col.editable,
                    refetch: refetch,
                    editableDataItems: editableDataItems,
                    dataIndex: col.dataIndex,
                    editableDataItemsActions,
                    actions,
                    multiple: col.multiple,
                    options: col.options,
                    type: col.type,
                };
            },
        };
    });

    if (data === null || data === undefined) return <Spin size="large" />;
    if (!data.length) return <h1>Данные не найдены</h1>;
    return (
        <TableEditContext.Provider value={{ setAllPageEdit: triggerPageEdit }}>
            <Table
                components={components}
                className={styles.table}
                dataSource={dataSource}
                pagination={paginationOptions}
                bordered
                columns={columns2 as ColumnTypes}
            />
        </TableEditContext.Provider>
    );
};

export default EditableTable;
