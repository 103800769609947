import { FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type Props = {
    isHidden?: boolean;
};

const HiddenContainer: FC<PropsWithChildren<Props>> = ({
    isHidden,
    children,
}) => {
    return (
        <div className={classNames({ [styles.hidden]: isHidden })}>
            {children}
        </div>
    );
};

export default HiddenContainer;
