import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    ColorPallete,
    ITableCell,
    PeriodConfiguration,
    TableVariants,
} from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICreatingFormResponse } from "entities/ISGuide/types";
import { getCreatingFormConfig } from "entities/ISGuide/api";
import { IRadioFilterItem } from "shared/types/is-energy";
import { setDeep } from "shared/helpers";

const base: GuideCreatingForm = {
    form: [{ name: "energy", items: [] }],
    subFilters: [],
    getActualForm(filter?: { [key: string]: string }) {
        const trigger = filter?.energy ?? "";
        const subFilter =
            this.subFilters.find((el) => el.name === trigger) ??
            this.subFilters?.[0] ??
            null;

        if (!subFilter) return this.form;
        return [
            this.form[0],
            { ...subFilter, name: "EnergySourceId" },
            ...this.form.slice(1),
        ];
    },
};

type GuideCreatingForm = {
    form: IRadioFilterItem[];
    subFilters: IRadioFilterItem[];
    getActualForm: (value?: { [key: string]: string }) => IRadioFilterItem[];
};

type ActiveIndex = {
    table: number;
    rate: number;
};

interface InitialState {
    promises: Record<string, Record<string, string>>;
    filteredString: string;
    colors: ColorPallete;
    currentVariant: TableVariants;
    filterPeriodCurrent: PeriodConfiguration | null;
    activeIndex: ActiveIndex | null;
    fontSize: string;
    pointedRows: number[];
    actionCell: ITableCell | null;
    titleGenerator: (filter: {
        [key: string]: string | number | null;
    }) => string;
    showHiddenValues: boolean;
    resources: ICreatingFormResponse[];
    guideCreatingForm: GuideCreatingForm;
}

const fontSizeFromStorage: string | null = localStorage.getItem("fontSize");

const initialColors = localStorage.getItem("colors");

const initialState: InitialState = {
    promises: {},
    colors: initialColors
        ? JSON.parse(initialColors)
        : {
              header: "#fefe22",
              name: "#ccffcc",
              column: "#ccffcc",
          },
    filteredString: "",
    currentVariant: "Electric",
    filterPeriodCurrent: null,
    activeIndex: null,
    fontSize: fontSizeFromStorage ?? "16",
    pointedRows: [],
    actionCell: null,
    titleGenerator: (item: { [key: string]: string | number | null }) => "",
    showHiddenValues: false,
    resources: [],
    guideCreatingForm: {
        form: [],
        subFilters: [],
        getActualForm(filter?: { [key: string]: string }) {
            return [];
        },
    },
};

export const fetchResources = createAsyncThunk(
    "asyncGetResources",
    async (_, thunkApi) => {
        const response = await getCreatingFormConfig();

        return response;
    }
);

const excelSlice = createSlice({
    name: "excelSlice",
    initialState,
    reducers: {
        addEditing: (state, action: PayloadAction<[string, string]>) => {
            const [key, value] = action.payload;
            state.promises = setDeep(
                value,
                key,
                String(state.activeIndex?.rate),
                state.promises
            );
        },
        clearEditings: (state, action: PayloadAction<number | undefined>) => {
            if (!action.payload) {
                state.promises = {};
            } else {
                const clone = { ...state.promises };
                delete clone[String(action.payload)];
                state.promises = clone;
            }
            return state;
        },
        setFilterValue: (state, action: PayloadAction<string>) => {
            state.filteredString = action.payload;
        },
        changeColor: (
            state,
            action: PayloadAction<{ key: keyof ColorPallete; color: string }>
        ) => {
            state.colors = {
                ...state.colors,
                [action.payload.key]: action.payload.color,
            };
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        rewriteColors: (state, action: PayloadAction<ColorPallete>) => {
            state.colors = action.payload;
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        formPeriod: (
            state,
            action: PayloadAction<PeriodConfiguration | null>
        ) => {
            state.filterPeriodCurrent = action.payload;
        },
        changeActiveIndex: (
            state,
            action: PayloadAction<ActiveIndex | null>
        ) => {
            state.activeIndex = action.payload;
            state.pointedRows = [];
        },
        togglePointedRows: (state, action: PayloadAction<number>) => {
            state.pointedRows = state.pointedRows.includes(action.payload)
                ? state.pointedRows.filter((row) => row !== action.payload)
                : [...state.pointedRows, action.payload];
        },
        setFontSize: (state, action: PayloadAction<string>) => {
            state.fontSize = action.payload;
            localStorage.setItem("fontSize", state.fontSize);
        },
        changeShowHiddenValuesStatus: (
            state,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.showHiddenValues = action.payload ?? !state.showHiddenValues;
        },
        regenerateTitle: (
            state,
            action: PayloadAction<
                (filter: { [key: string]: string | number | null }) => string
            >
        ) => {
            state.titleGenerator = action.payload;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchResources.fulfilled, (state, action) => {
            if (
                JSON.stringify(state.resources) !==
                JSON.stringify(action.payload)
            ) {
                state.resources = action.payload;
                let chida = action.payload.reduce<GuideCreatingForm>(
                    (acc: GuideCreatingForm, cur: ICreatingFormResponse) => {
                        const energyItem = acc.form.find(
                            (el) => el.name === "energy"
                        )!;
                        const hasElem = energyItem.items.find(
                            (el) => el.value === cur.AxisCode
                        );
                        if (hasElem) {
                            acc = {
                                ...acc,
                                subFilters: acc.subFilters.map((el) =>
                                    el.name === cur.AxisCode
                                        ? {
                                              ...el,
                                              items: [
                                                  ...el.items,
                                                  {
                                                      key: cur.EnergySourceName,
                                                      value: String(
                                                          cur.EnergySourceId
                                                      ),
                                                  },
                                              ],
                                          }
                                        : el
                                ),
                            };
                        } else {
                            acc = {
                                ...acc,
                                form: acc.form.map((el) =>
                                    el.name === "energy"
                                        ? {
                                              ...el,
                                              items: [
                                                  ...el.items,
                                                  {
                                                      key: cur.AxisName,
                                                      value: cur.AxisCode,
                                                  },
                                              ],
                                          }
                                        : el
                                ),
                                subFilters: [
                                    ...acc.subFilters,
                                    {
                                        name: cur.AxisCode,
                                        items: [
                                            {
                                                key: cur.EnergySourceName,
                                                value: String(
                                                    cur.EnergySourceId
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            };
                        }
                        return acc;
                    },
                    base
                );
                chida = {
                    ...chida,
                    form: [...chida.form],
                };
                state.guideCreatingForm = chida;
            }
        });

        builder.addCase(fetchResources.rejected, (state) => {
            state.resources = [];
        });
    },
});

export const { actions, reducer } = excelSlice;
