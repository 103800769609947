import { RootState } from '@/app/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RestInstanse } from '../../app/axiosInstance';
import { getAuth } from '../../helpers/getAuth';
import { RequestInterface, RequestState } from '../requestTypes';

export interface ISupplyPointCell{
    Id: number
        FriendlyName: string
        Comment: string
        Name: string
        Parser: string
        Tag: string
        Tag1: string
        Granularity: number
        Measurable: boolean
        Type: 'INPUT' | 'OUTPUT'
}
interface IInitialState {
    supplyPointCellAll: ISupplyPointCell[]
    supplyPointCellAllStatus: RequestState

}
export const fetchSupplyPointCell = createAsyncThunk<
	any,
	RequestInterface<ISupplyPointCell[]>
>(
	'supplyPointCell/getAll',
	async ({ onSuccess = () => null, onError = () => null }) => {
		const response = await RestInstanse.get(`/supply-point-cell`, getAuth())
		const data: any = await response.data

		if (response.status === 200) {
			onSuccess(data)
		} else {
			onError()
		}

		return data
	}
)


export const cellMappingSlice = createSlice({
  name: "CellMappingSlice",
  initialState: {
    supplyPointCellAll: [],
    supplyPointCellAllStatus: undefined

  } as IInitialState,
  reducers: {},
  extraReducers(builder) {
	builder.addCase(fetchSupplyPointCell.fulfilled, (state, action) => {
		state.supplyPointCellAll = action.payload
		state.supplyPointCellAllStatus = 'fulfilled'
	  });
  },
});

export const selectSupplyPointCellAll = (
	state: RootState,
) => state.CellMapping.supplyPointCellAll

export const selectSupplyPointCellAllStatus = (
	state: RootState,
) => state.CellMapping.supplyPointCellAllStatus