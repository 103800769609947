import React, { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type Props = {
    icon?: JSX.Element;
};

const TablePrimaryButton: FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & Props
> = (props) => {
    const { children, className, icon, ...other } = props;

    return (
        <button
            {...other}
            className={classNames(styles["primary-button"], className)}
        >
            {children}
            {icon}
        </button>
    );
};

export default TablePrimaryButton;
