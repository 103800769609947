import { SettingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Tooltip, Typography } from "antd";
import { FC } from "react";
import styles from "./styles.module.css";
import { TableTabItem } from "../../types";
import ActionButtons from "../ActionButtons";

type Props = {
    tableTabItem?: TableTabItem;
    onEditReport?: () => void;
    canEditingTab: boolean;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    onCancel: () => void;
    onAccept: () => void;
    newItem: {
        [key: string]: string | number | null;
    } | null;
};

const TableTitle: FC<Props> = (props) => {
    const {
        tableTabItem,
        onEditReport,
        canEditingTab,
        setTrigger,
        onCancel,
        onAccept,
        newItem,
    } = props;

    return (
        <Flex vertical gap={12}>
            {tableTabItem && (
                <Flex align="center" justify="space-between" gap={20}>
                    <div className={styles["title-container"]}>
                        <Typography.Title level={1} className={styles["title"]}>
                            {tableTabItem.fullName}
                        </Typography.Title>{" "}
                        {canEditingTab && (
                            <div className={styles["editing-container"]}>
                                <Tooltip
                                    title={
                                        !!newItem
                                            ? "Примите или отмените текущее редактирование"
                                            : "Редактировать"
                                    }
                                >
                                    <Button
                                        disabled={!!newItem}
                                        className={styles["settings-icon"]}
                                        onClick={onEditReport}
                                        icon={<SettingOutlined />}
                                    />
                                </Tooltip>
                                {!!newItem && (
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Button: {
                                                    defaultBg: "#32B232",
                                                    defaultActiveColor:
                                                        "#32B232",
                                                    defaultActiveBorderColor:
                                                        "#32B232",
                                                    primaryColor: "white",
                                                    colorPrimary: "#32B232",
                                                    colorPrimaryHover: "green",
                                                },
                                            },
                                        }}
                                    >
                                        <Flex align="center" gap={8}>
                                            <Button
                                                className={styles["sub-button"]}
                                                type="primary"
                                                onClick={onAccept}
                                            >
                                                Принять
                                            </Button>
                                            <Button
                                                className={styles["sub-button"]}
                                                type="primary"
                                                onClick={onCancel}
                                                danger
                                            >
                                                Отклонить
                                            </Button>
                                        </Flex>
                                    </ConfigProvider>
                                )}
                            </div>
                        )}
                    </div>

                    <ActionButtons setTrigger={setTrigger} />
                </Flex>
            )}

            {/* <div className={styles.subtitle}>
                    {newItemFilter && (
                        <>
                            <Typography.Title
                                style={{ margin: 0 }}
                                className={styles["subtitle__text"]}
                                level={2}
                            >
                                {subtitle}
                            </Typography.Title>
                            <Flex align="center" gap={8}>
                                <Button
                                    className={styles["sub-button"]}
                                    type="primary"
                                    onClick={onAcceptCreating}
                                >
                                    Принять
                                </Button>
                                <Button
                                    className={styles["sub-button"]}
                                    type="primary"
                                    onClick={onCancel}
                                    danger
                                >
                                    Отклонить
                                </Button>
                            </Flex>
                        </>
                    )}
                </div> */}
        </Flex>
    );
};

export default TableTitle;
