import { useEffect, useMemo, useRef, useState } from "react";
import { getCurrentTabs } from "../api";
import { TablePageProps, TableTabItem, TabsParams } from "../types";
import { useActions, useAppSelector } from "app/hooks";
import { Flex, Modal } from "antd";
import { TableTitle, TabsList } from "../components";
import { Archive } from "widgets/ExcelTable";
import { callSuccess } from "shared/helpers";

export const withExcelPage = <T extends object>(
    Component: React.ComponentType<TablePageProps>,
    type: TabsParams,
    renameFunction: (data: { [key: string]: string | number | null }) => string,
    setShortName: (data: { [key: string]: string | number | null }) => string,
    newTabText: string,
    canEditingTab: boolean,
    creatableFunction: (
        data: T
    ) => Promise<{ id: number; success: boolean; tableId: number }>
) => {
    return () => {
        function removeTab(id: number) {
            setTabs((prev) => prev.filter((item) => item.id !== id));
        }

        const updateNewItem = (val: {
            [key: string]: string | number | null;
        }) => {
            if (isEditing.current) {
                setNewItem(val);
            } else {
                modifiyTabs(val);
            }
        };

        const createNewTab = (item: TableTabItem) => {
            if (tabs.some((tab) => tab.id === item.id)) {
                changeActiveIndex({ rate: item.id, table: item.tableId });
                return;
            }
            setTabs((prev) => [...prev, item]);
        };

        async function modifiyTabs(newItemModel: {
            [key: string]: string | number | null;
        }) {
            const newItemId = await creatableFunction(newItemModel as T);
            if (newItemId.success) {
                if (isEditing.current) {
                    setTabs((prev) =>
                        prev.map((el) =>
                            el.id === activeIndex?.rate
                                ? { ...el, filtersState: newItemModel }
                                : el
                        )
                    );
                } else {
                    createNewTab({
                        id: newItemId.id,
                        tableId: newItemId.tableId,
                        fullName: titleGenerator(newItemModel),
                        name: setShortName(newItemModel),
                        filtersState: newItemModel,
                    });
                    changeActiveIndex({
                        rate: newItemId.id,
                        table: newItemId.tableId,
                    });
                }
                callSuccess(
                    `Отчет ${isEditing.current ? "изменен" : "сформирован"}`
                );
            } else {
                setErrorId(newItemId.id);
            }
            isEditing.current = false;
            setNewItem(null);
        }

        const [tabs, setTabs] = useState<TableTabItem[]>([]);

        const isEditing = useRef<boolean>(false);
        const [trigger, setTrigger] = useState<boolean>(false);
        const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
        const [newItem, setNewItem] = useState<{
            [key: string]: string | number | null;
        } | null>(null);

        const { titleGenerator, activeIndex, promises } = useAppSelector(
            (state) => state.Excel
        );
        const [errorId, setErrorId] = useState<number | null>(null);

        const {
            changeActiveIndex,
            regenerateTitle,
            clearEditings,
            changeShowHiddenValuesStatus,
        } = useActions();

        const currentTableItem = useMemo<TableTabItem | undefined>(() => {
            return tabs.find((item) => item.id === activeIndex?.rate);
        }, [activeIndex, tabs]);

        useEffect(() => {
            getCurrentTabs({ type }).then((data) => {
                setTabs(data);
                changeActiveIndex(
                    data.length
                        ? { rate: data[0].id, table: data[0].tableId }
                        : null
                );
            });
            regenerateTitle(renameFunction);
            return () => {
                changeActiveIndex(null);
                clearEditings();
                changeShowHiddenValuesStatus(false);
            };
        }, []);

        const [isEditingModalOpen, setIsEditingModalOpen] =
            useState<boolean>(false);

        const onCreateItem = () => {
            if (Object.keys(promises).length) {
                setIsEditingModalOpen(true);
            } else {
                setIsOpenModal(true);
            }
        };

        return (
            <Flex vertical gap={12}>
                <TableTitle
                    onEditReport={() => {
                        isEditing.current = true;
                        setIsOpenModal(true);
                    }}
                    tableTabItem={currentTableItem}
                    canEditingTab={canEditingTab}
                    setTrigger={setTrigger}
                    onCancel={() => setNewItem(null)}
                    onAccept={() => {
                        if (newItem) modifiyTabs(newItem);
                    }}
                    newItem={newItem}
                />

                <Flex align="flex-start" justify="space-between" gap={12}>
                    <TabsList
                        items={tabs}
                        isEditing={isEditing.current}
                        createTabTitle={newTabText}
                        onCreateItem={onCreateItem}
                        deleteItem={removeTab}
                    />

                    <Archive
                        type={type}
                        tabs={tabs}
                        // filters={{}}
                        createTab={createNewTab}
                    />
                </Flex>

                <Modal
                    open={isEditingModalOpen}
                    title="Внимание"
                    centered
                    width={800}
                    onCancel={() => setIsEditingModalOpen(false)}
                    onClose={() => setIsEditingModalOpen(false)}
                    footer={() => <></>}
                >
                    <strong>
                        В ваших текущих отчетах имеются несохраненные изменения.
                        Примите их или сбросьте их перед тем, как создать новый
                        отчет
                    </strong>
                </Modal>

                <Component
                    isModalOpen={isOpenModal}
                    closeModal={() => setIsOpenModal(false)}
                    createNewTab={createNewTab}
                    isEditing={isEditing.current}
                    trigger={trigger}
                    errorId={errorId}
                    cleanErrorId={() => setErrorId(null)}
                    currentTableItem={currentTableItem}
                    updateNewItem={updateNewItem}
                />
            </Flex>
        );
    };
};
