export class SerializedObject<T extends {}> {
    obj: T;

    constructor(val: T) {
        this.obj = val;
    }

    get isSerialized(): boolean {
        let isOk = true;
        Object.values(this.obj).forEach((el) => {
            if (Array.isArray(el)) {
                isOk = isOk && !!el.length;
            }
            if (typeof el === "string") {
                isOk = isOk && !!el.trim().length;
            }
            if (typeof el === "object" && !Array.isArray(el)) {
                isOk = isOk && el !== null;
            }
            if (typeof el === "number" && !Array.isArray(el)) {
            }
        });
        return isOk;
    }

    public clone(newValue: T): SerializedObject<T> {
        return new SerializedObject(newValue);
    }
}
