import { useEffect, useRef, useState } from "react"

export const useFirstLoading = (isLoading: boolean) => {


    const hasLoaded = useRef(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isLoading && !hasLoaded.current) {
            hasLoaded.current = true;
            setLoading(false);
        }
    }, [isLoading])

    return loading;
}