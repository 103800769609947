import { Modal, Radio } from "antd";
import { createElement, FC, useMemo } from "react";
import { getRealTable } from "entities/ExcelTable/api";
import { TableSearcher } from "entities/ExcelTable/components";
import { periodConfiguration } from "entities/ExcelTable/helpers/table.constants";
import { TablePageProps } from "entities/ExcelTable/types";
import { usePeriodFilter } from "ExcelTable/components/ExcelHeaderPanel/ExcelHeaderTitle/hooks/usePeriodFIlter";
import { HiddenContainer } from "ExcelTable/shared/components";
import ExcelTable from "ExcelTable/components/ExcelTable";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import { BigLoader } from "components/UI";
import { getReportName2 } from "ExcelTable/helpers/methods";
import { useLocation } from "react-router-dom";
import { withExcelPage } from "entities/ExcelTable/hocs/withExcelPage";

const typeDictionary: Record<string, number> = {
    electric: 2,
    heat: 1,
};

const BalancePage: FC<TablePageProps> = (props) => {
    const location = useLocation();

    const actualType = useMemo<number>(() => {
        return typeDictionary[location.pathname.split("/").at(-1) ?? ""] ?? 1;
    }, [location.pathname]);

    const {
        isModalOpen,
        closeModal,
        isEditing,
        currentTableItem,
        updateNewItem,
        trigger,
    } = props;

    const { filter, changeFilter, cleanFilters, settings, updateSettings } =
        usePeriodFilter(isModalOpen);

    const {
        data,
        trigger: refetch,
        isLoading,
    } = useFetch(getRealTable, {
        TypeId: actualType,
        variant: "balance",
    });

    useEffectSkipFirstRender(() => {
        refetch();
    }, trigger);

    return (
        <>
            <Modal
                width={1100}
                open={isModalOpen}
                title={`${
                    isEditing ? "Редактировать" : "Сформировать"
                } отчет по периоду`}
                onCancel={() => {
                    closeModal();
                    cleanFilters();
                }}
                onOk={() => {
                    updateNewItem({ mode: settings, ...filter });
                    closeModal();
                }}
            >
                <Radio.Group
                    onChange={(e) => updateSettings(e.target.value)}
                    value={settings}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    {periodConfiguration.map(({ name, code }) => (
                        <Radio.Button key={code} value={code}>
                            {name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                {periodConfiguration.map(({ code, Value }) => (
                    <HiddenContainer isHidden={settings !== code} key={code}>
                        {createElement(Value, {
                            filters: filter,
                            updateFilter: changeFilter,
                            isActive: settings === code,
                        })}
                    </HiddenContainer>
                ))}
            </Modal>

            <TableSearcher />
            {data ? (
                <ExcelTable
                    currentTable={{
                        ...data,
                        title: currentTableItem?.fullName ?? "",
                    }}
                    isLoading={isLoading}
                />
            ) : (
                <BigLoader />
            )}
        </>
    );
};

export default withExcelPage(
    BalancePage,
    "balance",
    getReportName2,
    (data: { [key: string]: string | number | null }) =>
        getReportName2(data, true),
    "Новый отчет",
    true,
    async (data: any) => {
        return {
            success: true,
            id: new Date().getTime(),
            tableId: new Date().getTime(),
        };
    }
);
