import { getAuth } from "helpers/getAuth";
import { RestInstanse } from "../../app/axiosInstance";

export class ApiModule {
    static async get<T>(
        url: string,
        params?: { [key: string]: string | number }
    ) {
        try {
            const response = await RestInstanse.get<T>(url, {
                ...getAuth(),
                params: params,
            });
            return response?.data ?? undefined;
        } catch (err) {
            console.error(err);
        }
    }

    static async post<T, K>(
        url: string,
        data?: K,
        params?: { [key: string]: string }
    ) {
        try {
            const response = await RestInstanse.post<T>(url, data, {
                ...getAuth(),
                params: params,
            });
            return response.data;
        } catch (err) {
            console.error(err);
        }
    }

    static async put<T, K>(
        url: string,
        data?: K,
        params?: { [key: string]: string }
    ) {
        try {
            const response = await RestInstanse.put<T>(url, data, {
                ...getAuth(),
                params: params,
            });
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    static async delete<T>(url: string, params?: { [key: string]: string }) {
        try {
            await RestInstanse.delete<T>(url, {
                ...getAuth(),
                params: params,
            });
        } catch (err) {
            console.error(err);
        }
    }
}
