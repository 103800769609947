import { useCallback, useEffect, useState } from "react";
import { periods } from "../utils";
import { PeriodFilter, PeriodVariants } from "entities/ExcelTable/types";
import { availableYears, months } from "shared/constants/common";

const years = availableYears.map((el) => ({
    name: el.key,
    value: +el.value.split("-")[0],
}));

const defaultFilters: PeriodFilter = {
    month: months.find((el) => el.value === new Date().getMonth() + 1)!.value,
    year: new Date().getFullYear(),
    period: periods[0].value,
    date: null,
};

type PeriodKey = keyof PeriodFilter;

interface ReturnType {
    filter: PeriodFilter;
    changeFilter: (key: PeriodKey, value: PeriodFilter[PeriodKey]) => void;
    cleanFilters: () => void;
    settings: PeriodVariants;
    updateSettings: (variant: PeriodVariants) => void;
}

export const usePeriodFilter = (isOpen: boolean): ReturnType => {
    const [filter, setFilter] = useState<PeriodFilter>(defaultFilters);
    const [settings, setSettings] = useState<PeriodVariants>("Month");

    const changeFilter = useCallback(
        (key: PeriodKey, value: PeriodFilter[PeriodKey]) => {
            setFilter((prev) => ({ ...prev, [key]: value }));
        },
        [setFilter]
    );

    function cleanFilters() {
        setFilter(defaultFilters);
    }

    function updateSettings(variant: PeriodVariants) {
        setSettings(variant);
    }

    useEffect(() => {
        if (!isOpen) {
            cleanFilters();
            setSettings("Month");
        }
    }, [isOpen]);

    return { filter, changeFilter, cleanFilters, settings, updateSettings };
};
