import { FC } from "react";
import classNames from "classnames";
import styles from "./style.module.css";
import { useTableContext } from "../context";
import { ITableColumn } from "entities/ExcelTable/types";
import { withSortingClass } from "hocs/withSortingClass";

type Props = {
    column: Required<ITableColumn>["Children"][0];
    isHidden: boolean;
    
};

const ExcelHeaderSubCell: FC<Props & { sortingClass: string }> = (props) => {
    const { column, isHidden,  sortingClass } = props;
    const name = `Cell-${column.Id}`;

    const { toggleSorting } = useTableContext();

    return (
        <th
            className={classNames(
                styles["excel-sub-row__item"],
                styles[sortingClass],
                {
                    [styles.none]:
                        isHidden && "hidden" in column && column.hidden,
                }
            )}
            onClick={() => toggleSorting(name)}
        >
            {column.Name}
        </th>
    );
};

export default withSortingClass<Props>(ExcelHeaderSubCell);
