import React, { FC } from "react";
import styles from "./styles.module.css";
import { Radio } from "antd";

type Props = {
    title: string;
    value: string | boolean;
    description: string;
};

const RadioVariant: FC<Props> = React.memo((props) => {
    const { title, value, description } = props;

    return (
        <div className={styles["radio-variant"]}>
            <Radio value={value} >
                <div className={styles["radio-label"]}>
                    <p>{title}</p>
                    <span>{description}</span>
                </div>
            </Radio>
        </div>
    );
});

export default RadioVariant;
