import {
    ICellRequest,
    ResponseTable,
    TableTabItem,
    TabsParams,
} from "../types";

import { ApiModule } from "shared/ApiModule";

export interface ExcelParams {
    // type: TableVariants | null;
    TypeId: number;
    variant: TabsParams;
    // month?: number;
    // year: number;
    // period?: string;
}

export const getCurrentTabs = async (params: {
    type: TabsParams;
}): Promise<TableTabItem[]> => {
    const promise = new Promise<TableTabItem[]>((resolve) => {
        setTimeout(() => {
            resolve(
                params.type === "balance"
                    ? [
                          {
                              fullName:
                                  "МЕCЯЦ: 10 2024 - Весь месяц - [01.10.2024 - 31.10.2024]",
                              name: "мес.: ОКТ-2024 01-31",
                              filtersState: {
                                  mode: "Month",
                                  month: 10,
                                  year: 2024,
                                  period: "allMonth",
                                  date: null,
                              },
                              tableId: 1,
                              id: 1728287431010,
                          },
                      ]
                    : [
                          {
                              fullName: "Тепло 2024 / Переменные нормы",
                              name: "Тепло 2024 пер.",
                              filtersState: {
                                  energy: "heating",
                                  constant: "variable",
                                  year: "2024",
                              },
                              tableId: 3,
                              id: 3,
                          },
                          {
                              fullName: "Тепло 2024 / Постоянные нормы",
                              name: "Тепло 2024 пост.",
                              filtersState: {
                                  energy: "heating",
                                  constant: "constant",
                                  year: "2024",
                              },
                              tableId: 4,
                              id: 4,
                          },
                      ]
            );
        }, 200);
    });
    const result = await promise;
    return result;
};

export const getRealTable = async (
    params?: ExcelParams
): Promise<ResponseTable | null> => {
    if (
        params?.variant !== "balance" &&
        params?.TypeId !== 3 &&
        params?.TypeId !== 4
    ) {
        const guides = await getGuideTables(params?.TypeId ?? 0);
        return guides || null;
    }
    const data = await ApiModule.post<ResponseTable, { TypeId: number }>(
        "/resource-consumption-table",
        { TypeId: params?.TypeId ?? 1 }
    );
    return data || null;
};

const getGuideTables = async (
    reportTableId: number
): Promise<ResponseTable | null> => {
    const data = await ApiModule.get<ResponseTable>("/ReportTable", {
        reportTableId,
    });
    return data || null;
};

export const updateEditableCells = async (
    promises: Record<string, Record<string, string>>
): Promise<boolean> => {
    const data: ICellRequest[] = [];
    Object.entries(promises).forEach((pr) => {
        Object.entries(pr[1]).forEach((cell) => {
            data.push({
                RateTemplateId: parseInt(pr[0]),
                CellId: parseInt(cell[0]),
                Value: cell[1],
            });
        });
    });
    try {
        await ApiModule.put("/ReportTable/cells", data);
        return true;
    } catch (e) {
        return false;
    }
};

export const deleteRate = (id: number) => {
    ApiModule.delete(`/RateTemplate?rateTemplateId=${id}`);
};
