import { Flex, Select } from "antd";
import { FlexInLine } from "shared/components";
import { config, periods } from "../../utils";
import { FC, useEffect, useMemo } from "react";
import { IConfig } from "../../utils/types";
import { FiltersDatePicker } from "../../../../../../components/UI";
import { PeriodFilter } from "entities/ExcelTable/types";

const date = new Date();
const month = date.getMonth() + 1;
const year = date.getFullYear();

type Props = {
    filters: PeriodFilter;
    updateFilter: (
        key: keyof PeriodFilter,
        value: PeriodFilter[keyof PeriodFilter]
    ) => void;
    isActive: boolean;
};

const MonthSettings: FC<Props> = (props) => {
    const { filters, updateFilter, isActive } = props;

    useEffect(() => {
        if (isActive) {
            updateFilter("period", periods[0].value);
        }
    }, [isActive]);

    const filteredPeriods = useMemo(() => {
        if (filters.year === year && filters.month === month) {
            return periods;
        }
        return periods.filter(({ value }) => value !== "untilToday");
    }, [filters]);

    useEffect(() => {
        if (
            isActive &&
            filteredPeriods.every((el) => el.value !== filters.period)
        ) {
            updateFilter("period", filteredPeriods[0].value);
        }
    }, [filteredPeriods, isActive]);

    const configuration: IConfig[] = [
        ...config,
        { title: "Период", options: filteredPeriods, name: "period" },
    ];

    return (
        <FlexInLine gap={15} align="end">
            {configuration.map(({ title, options, name }) => (
                <Flex vertical gap={5} key={name}>
                    <label htmlFor={name}>{title}</label>
                    <Select
                        onChange={(val) => updateFilter(name, val)}
                        id={name}
                        value={filters[name]}
                        options={options.map((el) => ({
                            value: el.value,
                            label: <span>{el.name}</span>,
                        }))}
                    />
                </Flex>
            ))}
            {filters.period === "untilDate" && (
                <FiltersDatePicker
                    value={filters.date}
                    onChangeFilters={(val: string | null) =>
                        updateFilter("date", val)
                    }
                />
            )}
        </FlexInLine>
    );
};

export default MonthSettings;
