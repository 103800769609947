import React, { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type ButtonStyles = "default" | "gray";

const RedButton: FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: ButtonStyles }
> = (props) => {
    const { children, className, variant, ...other } = props;

    return (
        <button
            {...other}
            className={classNames(styles["red-button"], className, {
                [styles[variant ?? "default"]]: variant,
            })}
        >
            {children}
        </button>
    );
};

export default RedButton;
