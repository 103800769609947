import React, { FC, PropsWithChildren } from "react";

type Props = {
    onClick: (e: React.MouseEvent<any, MouseEvent>) => void;
};

const TableRow: FC<PropsWithChildren<Props>> = ({ children, onClick }) => {
    return (
        <tr onClick={onClick} className="table-row">
            {children}
        </tr>
    );
};

export default TableRow;
