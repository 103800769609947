export const months = [
    {
        value: 1,
        name: { full: "Январь", short: "ЯНВ" },
    },
    {
        value: 2,
        name: { full: "Февраль", short: "ФЕВ" },
    },
    {
        value: 3,
        name: { full: "Март", short: "МАРТ" },
    },
    { value: 4, name: { full: "Апрель", short: "АПР" } },
    { value: 5, name: { full: "Май", short: "МАЙ" } },
    { value: 6, name: { full: "Июнь", short: "ИЮНЬ" } },
    { value: 7, name: { full: "Июль", short: "ИЮЛЬ" } },
    { value: 8, name: { full: "Август", short: "АВГ" } },
    { value: 9, name: { full: "Сентябрь", short: "СЕН" } },
    { value: 10, name: { full: "Октябрь", short: "ОКТ" } },
    { value: 11, name: { full: "Ноябрь", short: "НОЯБ" } },
    { value: 12, name: { full: "Декабрь", short: "ДЕК" } },
];

const year = new Date().getFullYear();
export const availableYears = Array.from({ length: 11 }, (_, i) => {
    const value = String(year - 5 + i);
    return { key: value, value: value + "-01-01T00:00:00" };
});

export const currentYear = `${year}-01-01T00:00:00`

export const secretKey = 'секретныйКлючЛукойл'