import React, { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type Props = {
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
};

const Toggler: FC<Props> = (props) => {
    const { className, ...rest } = props;

    return (
        <input
            className={classNames(className, styles.toggle)}
            type="checkbox"
            {...rest}
        />
    );
};

export default Toggler;
