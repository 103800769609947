import { useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ITableLink } from "entities/ExcelTable/types";
import { LinksMainList } from "widgets/ExcelTable";
import { deleteRate } from "entities/ExcelTable/api";

const linkTree: ITableLink[] = [
    {
        link: "balance",
        name: "Энергобаланс",
        children: [
            {
                link: "balance/heat",
                name: "Тепло",
            },
            { link: "balance/fuel", name: "Топливо" },
            { link: "balance/electric", name: "Электроэнергия" },
            { link: "balance/compressed", name: "Сжатый воздух" },
        ],
    },
    {
        link: "guides",
        name: "Справочники",
        children: [
            {
                link: "guides/templates",
                name: "Шаблоны энергоресурсов",
            },
            { link: "guides/view", name: "Нормы" },
            { link: "guides/renovation", name: "Ремонтные работы" },
        ],
    },
];

const onOnlyIsEnergyRegExp = /is-energy$/;

const ISMainPage = () => {
    const location = useLocation();

    // useEffect(() => {
    //     [3,4].forEach(el => {
    //         deleteRate(el)
    //     })
        
    // },[])
 
    const actual = useMemo<ITableLink[] | undefined>(() => {
        if (onOnlyIsEnergyRegExp.test(location.pathname)) return linkTree;
        const actual = linkTree.find((el) =>
            location.pathname.includes(el.link)
        );
        if (actual) return [actual];
        return undefined;
    }, [location.pathname]);

    if (actual === undefined) return <Navigate to={"/page-404"} replace />;
    return <LinksMainList links={actual} />;
};

export default ISMainPage;
