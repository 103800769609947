import { FC, PropsWithChildren, useRef, useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const SpinnerUserIcon: FC<PropsWithChildren<{}>> = ({ children }) => {
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);

    const onMouseOver = () => {
        timer.current = setTimeout(() => {
            setIsActive(true);
        }, 1000);
    };

    const onMouseLeave = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        setIsActive(false);
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.active]: isActive,
            })}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseLeave}
        >
            {children}
        </div>
    );
};

export default SpinnerUserIcon;
