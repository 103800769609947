import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Crumb } from "entities/Breadcrumbs/components";
import { ICrumb } from "entities/Breadcrumbs/types";
import { crumbsDictionary } from "shared/constants/crumbsDictionary";
import styles from "./styles.module.css";

const Breadcrumbs = () => {
    const location = useLocation();

    const crumbs = useMemo<ICrumb[]>(() => {
        const paths = location.pathname.split("/");
        if (paths.length < 3) return [];
        return paths.slice(1).map((el, id) => {
            return {
                label: crumbsDictionary[el] ?? el,
                url: paths.slice(0, id + 2).join("/"),
                isActive: id === paths.length - 2,
            };
        });
    }, [location.pathname]);

    return (
        <div className={styles.breadcrumbs}>
            {crumbs.map((el) => (
                <Crumb key={el.url} crumb={el} />
            ))}
        </div>
    );
};

export default Breadcrumbs;
