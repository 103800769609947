import { getAuth } from "helpers/getAuth";
import { RestInstanse } from "../../../app/axiosInstance";
import { ISupplyPointCell } from "services/tableMappings/cellMappingSlice";

const BASE_URL = "/supply-point-cell";

export const createCell = async (data: ISupplyPointCell) => {
  try {
    await RestInstanse.post<ISupplyPointCell>(
      BASE_URL,
      data,
      getAuth()
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateCell = async (data: ISupplyPointCell) => {
  try {
    await RestInstanse.put<ISupplyPointCell>(BASE_URL, data, getAuth());
  } catch (error) {
    console.error(error);
  }
};

export const getCells = async () => {
  try {
    const response = await RestInstanse.get<ISupplyPointCell[]>(
      BASE_URL,
      getAuth()
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCell = async (data: ISupplyPointCell) => {
  try {
    await RestInstanse.delete(BASE_URL, {
      ...getAuth(),
      params: { id: data.Id },
    });
  } catch (error) {
    console.error(error);
  }
};

